import { Box, Flex, PseudoBox, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { IoMdImages } from 'react-icons/io';

const HtmlFileInput = styled(Box)`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

HtmlFileInput.defaultProps = { as: 'input', type: 'file' };

function FileInput({
  onChange,
  multiple,
  accept = '.png, .jpg, .jpeg',
  invalid,
  ...rest
}) {
  const ref = useRef({
    id: `image-${new Date().getTime() + Math.floor(Math.random() * 100)}`,
  });

  return (
    <PseudoBox
      p={10}
      width="100%"
      borderWidth="1px"
      borderRadius="md"
      borderStyle="dashed"
      bg="white"
      position="relative"
      transition="all 0.2s"
      _hover={{
        borderColor: invalid ? 'red.500' : 'gray.300',
      }}
      borderColor={invalid && 'red.500'}
      {...rest}
    >
      <HtmlFileInput
        id={ref.current.id}
        accept={accept}
        onChange={onChange}
        multiple={multiple}
      />
      <Flex
        as="label"
        htmlFor={ref.current.id}
        cursor="pointer"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <Text
          fontSize="sm"
          letterSpacing="wide"
          fontWeight="medium"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Box as={IoMdImages} color="currentColor" mr={2} size={4} />
          Feltöltés
        </Text>
      </Flex>
    </PseudoBox>
  );
}

export default FileInput;
