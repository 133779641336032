import {
  Select,
  Switch,
  Textarea,
  Divider,
  Button,
  Badge,
} from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { forwardRef } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { useApolloClient, gql } from '@apollo/client';
import Card from '../../components/Card';
import {
  AttributesInput,
  BrandSelect,
  CollectionsSelect,
  Field,
  ImageInput,
  ManufacturerSelect,
} from '../../components/Form';
import SidebarLayout, { Main, Sidebar } from '../../components/SidebarLayout';
import GeneratedInput from '../../components/Form/GeneratedInput';

const ProductForm = forwardRef(({ data, orders, onSubmit }, ref) => {
  const apolloClient = useApolloClient();

  const {
    id,
    updatedAt,
    title,
    description,
    images,
    price,
    compareAtPrice,
    cost,
    quantity,
    vat,
    status,
    featured,
    attributes,
    manufacturer,
    brand,
    collections,
    slug,
    keywords,
  } = data;

  return (
    <Formik
      innerRef={ref}
      key={updatedAt}
      initialValues={{
        title: title || '',
        description: description || '',
        images: images || [],
        price: price || '',
        compareAtPrice: compareAtPrice || '',
        cost: cost || '',
        quantity: quantity || '',
        vat: vat || 'VAT27',
        status: status || '',
        featured: featured || false,
        attributes: attributes || [],
        manufacturer: {
          id: manufacturer?.id || '',
        },
        brand: {
          id: brand?.id || '',
        },
        collections: collections || [],
        onSale: !!compareAtPrice,
        slug: slug || '',
        keywords: keywords || '',
      }}
      enableReinitialize
      validationSchema={yup.object({
        title: yup.string().required('Kötelező'),
        description: yup.string().required('Kötelező'),
        images: yup.array().of(yup.object()),
        price: yup.number().required('Kötelező'),
        compareAtPrice: yup.number(),
        cost: yup.string(),
        quantity: yup.number(),
        vat: yup.string(),
        status: yup.string(),
        featured: yup.bool(),
        attribues: yup.array().of(
          yup.object({
            key: yup.string(),
            value: yup.string(),
          })
        ),
        manufacturer: yup.object({
          id: yup.string().required('Kötelező'),
        }),
        brand: yup.object({
          id: yup.string().required('Kötelező'),
        }),
        collections: yup.array().of(
          yup.object({
            id: yup.string(),
          })
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ values: { title, onSale, featured } }) => {
        return (
          <>
            <Form>
              <SidebarLayout>
                <Main>
                  <Card>
                    <Field name="title" label="Cím" isRequired />
                    <Field
                      name="description"
                      label="Leírás"
                      as={Textarea}
                      isRequired
                    />
                    <Divider my={4} />
                    <Field
                      as={ImageInput}
                      name="images"
                      multiple
                      label="Képek"
                      containerProps={{ width: '100%' }}
                      errorProps={{ display: 'none' }}
                    />
                    <Divider my={4} />
                    <Field name="price" label="Ár" isRequired type="number" />
                    <Field
                      name="onSale"
                      label="Akciós"
                      as={Switch}
                      isChecked={!!onSale}
                    />
                    {onSale && (
                      <Field
                        name="compareAtPrice"
                        label="Összehasonlítási ár"
                        type="number"
                      />
                    )}
                    <Field name="vat" label="ÁFA" as={Select}>
                      <option value="" disabled>
                        Kérlek válassz
                      </option>
                      <option value="VAT0">0%</option>
                      <option value="VAT27">27%</option>
                    </Field>
                    <Field name="cost" label="Költség" type="number" />
                    <Divider my={4} />
                    <Field name="quantity" label="Mennyiség" type="number" />
                    <Field name="status" label="Állapot" as={Select}>
                      <option value="" disabled>
                        Kérlek válassz
                      </option>
                      <option value="AVAILABLE">Elérhető</option>
                      <option value="NOT_AVAILABLE">Nem érhető el</option>
                    </Field>
                  </Card>
                </Main>
                <Sidebar>
                  <Card>
                    <Field
                      name="featured"
                      label="Kiemelt"
                      as={Switch}
                      defaultIsChecked={featured}
                    />
                    <Divider my={4} />
                    <Field
                      possibleValues={!id && title.split(' ')}
                      name="attributes"
                      label="Tulajdonságok"
                      as={AttributesInput}
                    />
                    <Divider my={4} />
                    <Field
                      possibleValues={!id && title.split(' ')}
                      name="brand.id"
                      label="Márka"
                      as={BrandSelect}
                      isRequired
                    />
                    <Field
                      possibleValues={!id && title.split(' ')}
                      name="manufacturer.id"
                      label="Gyártó"
                      as={ManufacturerSelect}
                      isRequired
                    />
                    <Divider my={4} />
                    <Field
                      name="collections"
                      label="Kollekciók"
                      as={CollectionsSelect}
                    />
                    <Divider my={4} />
                    <Field
                      name="slug"
                      label="Slug"
                      as={GeneratedInput}
                      getGeneratedValue={(v) =>
                        slugify(v.title, { lower: true })
                      }
                    />
                    <Field
                      name="keywords"
                      label="Keywords"
                      as={GeneratedInput}
                      getGeneratedValue={(v) => {
                        const keywords = v.title.toLowerCase().split(' ');

                        try {
                          const manufacturer = apolloClient.readFragment({
                            id: `Manufacturer:${v.manufacturer.id}`,
                            fragment: gql`
                              fragment manufacturer on Manufacturer {
                                id
                                name
                              }
                            `,
                          });

                          if (manufacturer?.name) {
                            keywords.push(manufacturer?.name.toLowerCase());
                          }
                        } catch (e) {
                          console.log(e);
                        }

                        try {
                          const brand = apolloClient.readFragment({
                            id: `Brand:${v.brand.id}`,
                            fragment: gql`
                              fragment brand on Brand {
                                id
                                name
                              }
                            `,
                          });

                          if (brand?.name) {
                            keywords.push(brand?.name.toLowerCase());
                          }
                        } catch (e) {
                          console.log(e);
                        }

                        console.log(keywords);

                        return [...new Set(keywords)].join(', ');
                      }}
                    />
                  </Card>
                  <Button
                    as={Link}
                    to={{
                      pathname: '/orders',
                      search: `?q=${id}`,
                    }}
                    variantColor="blue"
                    isDisabled={!orders.length}
                    pointerEvents={!orders.length && 'none'}
                  >
                    Rendelések
                    <Badge
                      variantColor="blue"
                      borderRadius="full"
                      position="absolute"
                      top="-0.25rem"
                      right="-0.25rem"
                      height={8}
                      minWidth="2rem"
                      fontSize="md"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderWidth="2px"
                      borderColor="gray.50"
                    >
                      {orders.length}
                    </Badge>
                  </Button>
                </Sidebar>
              </SidebarLayout>
            </Form>
          </>
        );
      }}
    </Formik>
  );
});

export default ProductForm;
