import React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Header from './Header';
import Container from './Container';

function Layout({ children, ...rest }) {
  const { pathname } = useLocation();

  if (['/login'].includes(pathname)) {
    return children;
  }

  return (
    <Container {...rest}>
      <Box minHeight="100vh">
        <Flex>
          <Navigation display={{ xs: 'none', lg: 'block' }} />
          <Box width="100%">
            <Header />
            <Box p={4}>{children}</Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
}

export default Layout;
