import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Text,
  MenuDivider,
  Avatar,
  AvatarBadge,
} from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import Navigation from './Navigation';
import { LogoutMutation, LoggedInQuery, ProfileQuery } from '../graphql';

function Header() {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [logout, { data, client }] = useMutation(LogoutMutation);
  const { data: profile, loading } = useQuery(ProfileQuery);

  useEffect(() => {
    if (data) {
      client.writeQuery({
        query: LoggedInQuery,
        data: { loggedIn: false },
      });
    }
  }, [client, data]);

  return (
    <Box
      height="header"
      alignItems="center"
      display="flex"
      width="100%"
      bg="white"
      boxShadow="sm"
      zIndex="sticky"
      position="sticky"
      top="0"
      p={4}
    >
      <IconButton
        display={{ xs: 'block', lg: 'none' }}
        icon={FaBars}
        onClick={onToggle}
      />
      <Drawer size="2xs" isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <Navigation />
        </DrawerContent>
      </Drawer>
      <Flex flex="1" />
      <Menu autoSelect={false}>
        <MenuButton as={Button} isLoading={loading} variant="ghost" px={1}>
          <Avatar size="sm" name={profile?.me.name}>
            <AvatarBadge size="1.25em" bg="green.400" />
          </Avatar>
        </MenuButton>
        <MenuList>
          <Box px={4} py={1}>
            <Flex alignItems="center" py={1}>
              <Avatar size="sm" name={profile?.me.name} mr={2}>
                <AvatarBadge size="1.25em" bg="green.400" />
              </Avatar>
              <Text fontSize="xl" fontWeight="medium">
                {profile?.me.name}
              </Text>
            </Flex>
            <Text fontSize="xs" color="gray.600">
              {profile?.me.email}
            </Text>
          </Box>
          <MenuDivider />
          <MenuItem onClick={() => logout()}>Kijelentkezés</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default Header;
