import { Select, Stack } from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { forwardRef } from 'react';
import * as yup from 'yup';
import Card from '../../components/Card';
import { Field } from '../../components/Form';

const ShippingMethodForm = forwardRef(({ data, onSubmit }, ref) => {
  const { updatedAt, name, description, price, vat, type } = data || {
    name: '',
    description: '',
    price: '',
    vat: '',
    collections: [],
    type: '',
  };

  return (
    <Formik
      innerRef={ref}
      key={updatedAt}
      initialValues={{
        name,
        description,
        price,
        vat: vat || 'VAT27',
        type: type || 'ADDRESS',
      }}
      enableReinitialize
      validationSchema={yup.object({
        name: yup.string().required('Kötelező'),
        description: yup.string().required('Kötelező'),
        price: yup.string().required('Kötelező'),
        vat: yup.string(),
        type: yup.string(),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <Stack spacing={2}>
          <Card>
            <Field name="name" label="Név" isRequired />
            <Field name="description" label="Leírás" isRequired />
            <Field name="price" label="Ár" isRequired type="number" />
            <Field name="vat" label="ÁFA" as={Select}>
              <option value="" disabled>
                Kérlek válassz
              </option>
              <option value="VAT0">0%</option>
              <option value="VAT27">27%</option>
            </Field>
            <Field name="type" label="Szállítás típusa" as={Select}>
              <option value="" disabled>
                Kérlek válassz
              </option>
              <option value="PICKUP">Személyes átvétel</option>
              <option value="ADDRESS">Lakcím</option>
              <option value="FOXPOST">FOXPOST Automata</option>
              <option value="POSTA">Posta</option>
              <option value="POSTAPONT">PostaPont</option>
              <option value="POSTA_CSOMAGAUTOMATA">Posta Csomagautomata</option>
            </Field>
          </Card>
        </Stack>
      </Form>
    </Formik>
  );
});

export default ShippingMethodForm;
