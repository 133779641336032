import { Stack } from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { forwardRef } from 'react';
import * as yup from 'yup';
import Card from '../../components/Card';
import { Field } from '../../components/Form';

const CollectionForm = forwardRef(({ data, onSubmit }, ref) => {
  const { title, updatedAt } = data || {
    title: '',
  };

  return (
    <Formik
      innerRef={ref}
      key={updatedAt}
      initialValues={{
        title,
      }}
      enableReinitialize
      validationSchema={yup.object({
        title: yup.string().required('Kötelező'),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <Stack spacing={2}>
          <Card>
            <Field name="title" label="Cím" isRequired />
          </Card>
        </Stack>
      </Form>
    </Formik>
  );
});

export default CollectionForm;
