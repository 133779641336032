import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorMode,
} from '@chakra-ui/core';
import { useField } from 'formik';
import React from 'react';

function Field({
  label,
  isRequired,
  as: Component = Input,
  containerProps = {},
  labelProps = {},
  errorProps = {},
  ...rest
}) {
  const [field, meta, helpers] = useField(rest);
  const { colorMode } = useColorMode();

  const labelColor = { light: 'gray.600', dark: 'whiteAlpha.900' };

  return (
    <FormControl
      isInvalid={meta.error && meta.touched}
      pb={2}
      {...containerProps}
    >
      {label && (
        <FormLabel
          htmlFor={field.name}
          fontSize="xs"
          letterSpacing="wide"
          textTransform="uppercase"
          fontWeight="semibold"
          isRequired={isRequired}
          color={labelColor[colorMode]}
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}
      <Component id={field.name} {...field} {...helpers} {...meta} {...rest} />
      <FormErrorMessage {...errorProps}>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

export default Field;
