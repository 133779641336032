import { Button, Heading, Stack, Text } from '@chakra-ui/core';
import React, { useState } from 'react';

/**
NEW
PAID
READY
SHIPPED
DELIVERED
CANCELLED
PAYMENT_FAILED
 */

function Action({
  order,
  update,
  sendShippedNotification,
  sendReadyNotification,
  createInvoice,
}) {
  const props = {
    order,
    update,
    sendShippedNotification,
    sendReadyNotification,
    createInvoice,
  };

  switch (order.status) {
    case 'NEW':
      return <New {...props} />;
    case 'READY':
      return <Ready {...props} />;
    case 'PAID':
      return <Paid {...props} />;
    case 'SHIPPED':
      return <Shipped {...props} />;
    case 'DELIVERED':
      return <Delivered {...props} />;
    case 'PAYMENT_FAILED':
      return <PaymentFailed {...props} />;
    case 'CANCELLED':
      return <Cancelled {...props} />;
    default:
      return null;
  }
}

function New({ order, update, sendReadyNotification }) {
  const [updating, setUpdating] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Új rendelés
      </Heading>
      {order.paymentMethod === 'CASH' && (
        <Text>Új készpénzes rendelés érkezett.</Text>
      )}
      {order.paymentMethod === 'TRANSFER' && (
        <Text>Új rendelés érkezett, beérkező utalásra vár.</Text>
      )}
      {order.paymentMethod === 'CARD' && (
        <Text>
          Új kártyás rendelés érkezett, de még nem történt meg a fizetés.
        </Text>
      )}
      <Stack isInline justifyContent="flex-end" mt={2}>
        {order.paymentMethod === 'CASH' && (
          <Button
            variantColor="blue"
            size="sm"
            isLoading={updating}
            onClick={async () => {
              setUpdating(true);
              await update({
                variables: {
                  id: order.id,
                  data: {
                    status: 'READY',
                    timeline: {
                      create: { message: 'Status changed to ready' },
                    },
                  },
                },
              });
              await sendReadyNotification({ variables: { orderId: order.id } });
              setUpdating(false);
            }}
          >
            Átvehető
          </Button>
        )}
        {order.paymentMethod !== 'CASH' && (
          <Button
            variantColor="blue"
            size="sm"
            isLoading={updating}
            onClick={async () => {
              setUpdating(true);
              await update({
                variables: {
                  id: order.id,
                  data: {
                    status: 'PAID',
                    timeline: {
                      create: { message: 'Status changed to paid' },
                    },
                  },
                },
              });
              setUpdating(false);
            }}
          >
            Fizetve
          </Button>
        )}
        <Button
          variant="outline"
          variantColor="red"
          size="sm"
          isLoading={cancelling}
          onClick={async () => {
            setCancelling(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'CANCELLED',
                  timeline: {
                    create: { message: 'Status changed to cancelled' },
                  },
                },
              },
            });
            setCancelling(false);
          }}
        >
          Rendelés törlése
        </Button>
      </Stack>
    </>
  );
}

function Ready({ order, update }) {
  const [updating, setUpdating] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Rendelés összekészítve
      </Heading>
      <Text>
        A rendelés összekészítve, értesítettük az ügyfelet az átvehetőségről.
      </Text>
      <Stack isInline justifyContent="flex-end" mt={2}>
        <Button
          variantColor="blue"
          size="sm"
          isLoading={updating}
          onClick={async () => {
            setUpdating(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'DELIVERED',
                  timeline: {
                    create: { message: 'Status changed to delivered' },
                  },
                },
              },
            });
            setUpdating(false);
          }}
        >
          Teljesítve
        </Button>
        <Button
          variant="outline"
          variantColor="red"
          size="sm"
          isLoading={cancelling}
          onClick={async () => {
            setCancelling(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'CANCELLED',
                  timeline: {
                    create: { message: 'Status changed to cancelled' },
                  },
                },
              },
            });
            setCancelling(false);
          }}
        >
          Rendelés törlése
        </Button>
      </Stack>
    </>
  );
}

function Paid({
  order,
  update,
  sendReadyNotification,
  sendShippedNotification,
}) {
  const [updating, setUpdating] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Rendelés fizetve
      </Heading>
      <Text>A rendelés fizetve.</Text>
      <Stack isInline justifyContent="flex-end" mt={2}>
        {order.shippingMethod.type === 'PICKUP' ? (
          <Button
            variantColor="blue"
            size="sm"
            isLoading={updating}
            onClick={async () => {
              setUpdating(true);
              await update({
                variables: {
                  id: order.id,
                  data: {
                    status: 'READY',
                    timeline: {
                      create: { message: 'Status changed to ready' },
                    },
                  },
                },
              });
              await sendReadyNotification({ variables: { orderId: order.id } });
              setUpdating(false);
            }}
          >
            Összekészítve
          </Button>
        ) : (
          <Button
            variantColor="blue"
            size="sm"
            isLoading={updating}
            onClick={async () => {
              setUpdating(true);
              await update({
                variables: {
                  id: order.id,
                  data: {
                    status: 'SHIPPED',
                    timeline: {
                      create: { message: 'Status changed to shipped' },
                    },
                  },
                },
              });
              await sendShippedNotification({
                variables: { orderId: order.id },
              });
              setUpdating(false);
            }}
          >
            Kiszállítás alatt
          </Button>
        )}
        <Button
          variant="outline"
          variantColor="red"
          size="sm"
          isLoading={cancelling}
          onClick={async () => {
            setCancelling(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'CANCELLED',
                  timeline: {
                    create: { message: 'Status changed to cancelled' },
                  },
                },
              },
            });
            setCancelling(false);
          }}
        >
          Rendelés törlése
        </Button>
      </Stack>
    </>
  );
}

function Shipped({ order, update }) {
  const [updating, setUpdating] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Kiszállítás alatt
      </Heading>
      <Text>A megrendelést kiszállításra átadtuk</Text>
      <Stack isInline justifyContent="flex-end" mt={2}>
        <Button
          variantColor="blue"
          size="sm"
          isLoading={updating}
          onClick={async () => {
            setUpdating(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'DELIVERED',
                  timeline: {
                    create: { message: 'Status changed to delivered' },
                  },
                },
              },
            });
            setUpdating(false);
          }}
        >
          Teljesítve
        </Button>
        <Button
          variant="outline"
          variantColor="red"
          size="sm"
          isLoading={cancelling}
          onClick={async () => {
            setCancelling(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'CANCELLED',
                  timeline: {
                    create: { message: 'Status changed to cancelled' },
                  },
                },
              },
            });
            setCancelling(false);
          }}
        >
          Rendelés törlése
        </Button>
      </Stack>
    </>
  );
}

function PaymentFailed({ order, update }) {
  const [updating, setUpdating] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Sikertelen fizetés
      </Heading>
      <Text>Sikertelen fizetés.</Text>
      <Stack isInline justifyContent="flex-end" mt={2}>
        <Button
          variantColor="blue"
          size="sm"
          isLoading={updating}
          onClick={async () => {
            setUpdating(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'PAID',
                  timeline: {
                    create: { message: 'Status changed to paid' },
                  },
                },
              },
            });
            setUpdating(false);
          }}
        >
          Fizetve
        </Button>
        <Button
          variant="outline"
          variantColor="red"
          size="sm"
          isLoading={cancelling}
          onClick={async () => {
            setCancelling(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'CANCELLED',
                  timeline: {
                    create: { message: 'Status changed to cancelled' },
                  },
                },
              },
            });
            setCancelling(false);
          }}
        >
          Rendelés törlése
        </Button>
      </Stack>
    </>
  );
}

function Cancelled({ order, update }) {
  const [updating, setUpdating] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Rendelés törölve
      </Heading>
      <Text>A rendelés törlésre került.</Text>
      <Stack isInline justifyContent="flex-end" mt={2}>
        <Button
          variantColor="blue"
          size="sm"
          isLoading={updating}
          onClick={async () => {
            setUpdating(true);
            await update({
              variables: {
                id: order.id,
                data: {
                  status: 'NEW',
                  timeline: {
                    create: { message: 'Status changed to new' },
                  },
                },
              },
            });
            setUpdating(false);
          }}
        >
          Újra nyitás
        </Button>
      </Stack>
    </>
  );
}

function Delivered({ order, createInvoice }) {
  const [creating, setCreating] = useState(false);

  return (
    <>
      <Heading size="sm" mb={2}>
        Rendelés teljesítve
      </Heading>
      <Text>
        A rendelés teljesítésre került.{' '}
        {order.invoiceId
          ? 'Nincs további teendő.'
          : 'Számlát még nem állítottál ki.'}
      </Text>
      {!order.invoiceId && (
        <Stack isInline justifyContent="flex-end" mt={2}>
          <Button
            variantColor="blue"
            size="sm"
            isLoading={creating}
            onClick={async () => {
              setCreating(true);
              await createInvoice({
                variables: {
                  orderId: order.id,
                },
              });
              setCreating(false);
            }}
          >
            Számla kiállítása
          </Button>
        </Stack>
      )}
    </>
  );
}

export default Action;
