import { FormErrorMessage } from '@chakra-ui/core';
import { useField } from 'formik';
import React from 'react';

export function formatError(error) {
  return { form: error.message.replace('GraphQL error: ', '') };
}

function Error({ error, ...rest }) {
  const meta = useField('form')[1];

  return (
    <FormErrorMessage isInvalid={error || meta.error} my={2} {...rest}>
      {error ? formatError(error).form : meta.error}
    </FormErrorMessage>
  );
}

export default Error;
