import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Stack,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Divider,
  CloseButton,
} from '@chakra-ui/core';
import React, { useRef } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Loading from '../../components/Loading';
import ProductForm from './ProductForm';
import { UploadFileMutation } from '../../graphql';
import { invalidateCachedItems } from '../../components/DataTable';
import Label from '../../components/Label';

const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    updatedAt
    title
    price
    compareAtPrice
    cost
    description
    quantity
    images(orderBy: { index: asc }) {
      id
      url
      index
    }
    vat
    manufacturer {
      id
    }
    collections {
      id
    }
    brand {
      id
    }
    attributes {
      id
      key
      value
    }
    status
    featured
    slug
    keywords
  }
`;

const ProductQuery = gql`
  query ProductQuery($id: String!) {
    product(where: { id: $id }) {
      ...ProductFragment
    }
    orders(where: { items: { some: { product: { id: { equals: $id } } } } }) {
      id
    }
  }
  ${ProductFragment}
`;

const UpdateProductMutation = gql`
  mutation UpdateProductMutation($id: String!, $data: ProductUpdateInput!) {
    updateOneProduct(where: { id: $id }, data: $data) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
`;

const CreateProductMutation = gql`
  mutation CreateProductMutation($data: ProductCreateInput!) {
    createOneProduct(data: $data) {
      id
    }
  }
`;

const DeleteProductMutation = gql`
  mutation DeleteProductMutation($id: String!) {
    deleteOneProduct(where: { id: $id }) {
      id
    }
  }
`;

function Product() {
  const searchParams = useSearchParams();

  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const formRef = useRef();

  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState();
  const cancelConfirmationRef = React.useRef();

  const isCreate = id === 'new';
  const productToCopy = searchParams.get('productToCopy');

  const productId = id !== 'new' ? id : productToCopy;

  const { data, loading } = useQuery(ProductQuery, {
    variables: { id: productId },
    skip: isCreate && !productToCopy,
  });

  const [update, updateResult] = useMutation(UpdateProductMutation);
  const [_delete, deleteResult] = useMutation(DeleteProductMutation, {
    update: invalidateCachedItems,
  });

  const [create, createResult] = useMutation(CreateProductMutation, {
    update: invalidateCachedItems,
  });

  const [upload, uploadResult] = useMutation(UploadFileMutation);

  if (loading) {
    return <Loading />;
  }

  const { title, attributes, images } = data?.product || {
    attributes: [],
    images: [],
  };

  const { networkError } =
    createResult.error || updateResult.error || deleteResult.error || {};

  const { graphQLErrors } =
    createResult.error || updateResult.error || deleteResult.error || {};

  return (
    <Box position="relative">
      <Flex alignItems="flex-start" mb={2}>
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/products">
              Termékek
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{isCreate ? `Létrehozás` : title}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex flex="1" />
        <Stack isInline>
          {!isCreate && (
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon="chevron-down"
                  isLoading={deleteResult.loading}
                >
                  Műveletek
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      navigate({
                        pathname: '../new',
                        search: `?productToCopy=${id}`,
                      });
                    }}
                  >
                    Duplikáció
                  </MenuItem>
                  <MenuItem onClick={() => setIsConfirmationOpen(true)}>
                    Törlés
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
          <Button
            variantColor="blue"
            type="submit"
            onClick={() => formRef.current.submitForm()}
            isLoading={
              updateResult.loading ||
              uploadResult.loading ||
              createResult.loading
            }
          >
            Mentés
          </Button>
        </Stack>
      </Flex>
      {networkError?.result.errors.map(({ message }) => (
        <Alert status="error" mb={2} key={message}>
          <AlertIcon />
          <AlertTitle mr={2}>Hálózati hiba!</AlertTitle>
          <AlertDescription>{message}</AlertDescription>
        </Alert>
      ))}
      {graphQLErrors?.map(({ message }) => (
        <Alert status="error" mb={2} key={message}>
          <AlertIcon />
          <AlertTitle mr={2}>GraphQL hiba!</AlertTitle>
          <AlertDescription>{message}</AlertDescription>
        </Alert>
      ))}
      <ProductForm
        ref={formRef}
        key={id}
        orders={!isCreate ? data?.orders : []}
        data={
          isCreate
            ? {
                ...data?.product,
                id: undefined,
                images: [],
                attributes: data?.product.attributes.map(
                  ({ id, ...rest }) => rest
                ),
              }
            : {
                ...data?.product,
              }
        }
        onSubmit={async (values) => {
          const imagesToUpload = values.images?.filter(
            (i) => i.edited?.file || i.original?.file
          );

          let uploadData;

          if (imagesToUpload?.length) {
            try {
              const promises = imagesToUpload.map(async (image) => {
                return upload({
                  variables: {
                    files: [image.edited?.file || image.original?.file],
                  },
                });
              });

              const [...results] = await Promise.all(promises);

              uploadData = {
                upload: results.map(({ data }) => data.upload[0]),
              };

              toast({
                title: 'Sikeres képfeltöltés!',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom',
              });
            } catch (e) {
              toast({
                title: 'Sikertelen képfeltöltés!',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom',
              });

              return;
            }
          }

          const newImages = imagesToUpload?.map((image, i) => ({
            ...image,
            url: uploadData?.upload?.[i].url,
          }));

          const data = {
            ...values,
            __typename: undefined,
            brand: {
              connect: {
                id: values.brand.id,
              },
            },
            manufacturer: {
              connect: {
                id: values.manufacturer.id,
              },
            },
            collections: {
              set: values.collections?.map(({ id }) => ({ id })),
            },
            onSale: undefined,
            compareAtPrice: values.onSale
              ? values.compareAtPrice || null
              : null,
            cost: values.cost || null,
            attributes: {
              update: values.attributes
                ?.filter((attribute) => attribute.id)
                .map(({ id, key, value }) => ({
                  where: { id },
                  data: { key, value },
                })),
              create: values.attributes
                ?.filter((attribute) => !attribute.id)
                .map(({ key, value }) => ({
                  key,
                  value,
                })),
              delete: attributes
                .filter(
                  (attribute) =>
                    !values.attributes.find((a) => a.id === attribute.id)
                )
                .map(({ id }) => ({ id })),
            },
            images: {
              update: values.images
                ?.filter((image) => image.url)
                .map((image) => {
                  const updated = newImages.find((i) => i.id === image.id);

                  if (updated) {
                    return updated;
                  }

                  return image;
                })
                .map(({ id, url }) => ({
                  where: { id },
                  data: {
                    url,
                    index: values.images.findIndex((i) => i.id === id),
                  },
                })),
              create: values.images
                ?.filter((image) => !image.url)
                .map((image) => {
                  const uploaded = newImages.find((i) => i.id === image.id);

                  if (uploaded) {
                    return uploaded;
                  }

                  return image;
                })
                .map(({ id, url }) => ({
                  url,
                  index: values.images.findIndex((i) => i.id === id),
                })),
              delete: images
                .filter(
                  (image) => !values.images.find((i) => i.id === image.id)
                )
                .map(({ id }) => ({ id })),
            },
          };

          if (id === 'new') {
            try {
              const result = await create({
                variables: {
                  data: {
                    ...data,
                    quantity: data.quantity || undefined,
                    vat: data.vat || undefined,
                    status: data.status || undefined,
                    attributes: data.attributes.create.length
                      ? {
                          create: data.attributes.create,
                        }
                      : undefined,
                    images: data.images.create.length
                      ? {
                          create: data.images.create,
                        }
                      : undefined,
                    collections: data.collections.set.length
                      ? {
                          connect: data.collections.set,
                        }
                      : undefined,
                  },
                },
              });
              navigate(`/products/${result.data.createOneProduct.id}`);

              toast({
                status: 'info',
                duration: 15000,
                isClosable: true,
                position: 'bottom',
                render: ({ onClose, ...rest }) => (
                  <Box
                    boxShadow="lg"
                    rounded="md"
                    bg="whiteAlpha.900"
                    m={2}
                    p={4}
                    maxWidth="15rem"
                    width="calc(100vw - 2rem)"
                    position="relative"
                    {...rest}
                  >
                    <Flex
                      flex="1"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Label textAlign="center" fontSize="sm">
                        Több is van?
                      </Label>
                      <CloseButton
                        size="sm"
                        onClick={onClose}
                        position="absolute"
                        top={3}
                        right={3}
                      />
                      <Divider />
                      <Button
                        variant="outline"
                        variantColor="blue"
                        onClick={() => {
                          navigate('/products/new');
                          onClose();
                        }}
                      >
                        Új termék hozzáadása
                      </Button>
                    </Flex>
                  </Box>
                ),
              });
            } catch (e) {
              console.error(e);

              return;
            }
          } else {
            try {
              await update({
                variables: {
                  id,
                  data,
                },
              });
            } catch (e) {
              console.error(e);

              return;
            }
          }

          toast({
            title: 'Sikeres mentés!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        }}
      />
      <AlertDialog
        isOpen={isConfirmationOpen}
        leastDestructiveRef={cancelConfirmationRef}
        onClose={() => setIsConfirmationOpen(false)}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Termék törlése
          </AlertDialogHeader>

          <AlertDialogBody>
            Biztosan szeretnéd törölni a terméket?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelConfirmationRef}
              onClick={() => setIsConfirmationOpen(false)}
            >
              Mégse
            </Button>
            <Button
              variantColor="red"
              onClick={async () => {
                setIsConfirmationOpen(false);
                try {
                  await _delete({ variables: { id } });
                  navigate(`../`);
                  toast({
                    title: 'Sikeres törlés!',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                } catch (e) {
                  toast({
                    title: 'Sikertelen törlés!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                }
              }}
              ml={3}
            >
              Törlés
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
}

export default Product;
