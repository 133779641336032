import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const UsersQuery = gql`
  query UsersQuery($first: Int!, $after: UserWhereUniqueInput, $query: String) {
    items: users(
      first: $first
      after: $after
      where: {
        OR: [{ name: { contains: $query } }, { email: { contains: $query } }]
      }
    ) {
      id
      name
      email
      role
    }
    count: usersCount(
      where: {
        OR: [{ name: { contains: $query } }, { email: { contains: $query } }]
      }
    )
  }
`;

function Users() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Felhasználók</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable templateColumns={['1fr', '1fr 1fr 1fr']} query={UsersQuery}>
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell>
                <Label>Név</Label>
              </Cell>
              <Cell>
                <Label>Email</Label>
              </Cell>
              <Cell>
                <Label>Jogosultság</Label>
              </Cell>
            </Row>
            {items.map(({ id, name, email, role }) => (
              <Row key={id} as={Link} to={`/users/${id}`}>
                <Cell label="Név">{name}</Cell>
                <Cell label="Email">{email}</Cell>
                <Cell label="Jogosultság">{role}</Cell>
              </Row>
            ))}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default Users;
