import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Button,
  useToast,
  Stack,
} from '@chakra-ui/core';
import React, { useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import ManufacturerForm from './ManufacturerForm';
import { UploadFileMutation } from '../../graphql';
import { invalidateCachedItems } from '../../components/DataTable';

const ManufacturerFragment = gql`
  fragment ManufacturerFragment on Manufacturer {
    id
    updatedAt
    name
    image {
      id
      url
    }
  }
`;

const ManufacturerQuery = gql`
  query ManufacturerQuery($id: String!) {
    manufacturer(where: { id: $id }) {
      ...ManufacturerFragment
    }
  }
  ${ManufacturerFragment}
`;

const UpdateManufacturerMutation = gql`
  mutation UpdateManufacturerMutation(
    $id: String!
    $data: ManufacturerUpdateInput!
  ) {
    updateOneManufacturer(where: { id: $id }, data: $data) {
      ...ManufacturerFragment
    }
  }
  ${ManufacturerFragment}
`;

const CreateManufacturerMutation = gql`
  mutation CreateManufacturerMutation($data: ManufacturerCreateInput!) {
    createOneManufacturer(data: $data) {
      id
    }
  }
`;

const DeleteManufacturerMutation = gql`
  mutation DeleteManufacturerMutation($id: String!) {
    deleteOneManufacturer(where: { id: $id }) {
      id
    }
  }
`;

function Manufacturer() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const formRef = useRef();

  const { data, loading } = useQuery(ManufacturerQuery, {
    variables: { id },
    skip: id === 'new',
  });

  const [update, updateResult] = useMutation(UpdateManufacturerMutation);
  const [_delete, deleteResult] = useMutation(DeleteManufacturerMutation, {
    update: invalidateCachedItems,
  });

  const [create, createResult] = useMutation(CreateManufacturerMutation, {
    update: invalidateCachedItems,
  });

  const [upload, uploadResult] = useMutation(UploadFileMutation);

  if (loading) {
    return <Loading />;
  }

  const { name, image } = data?.manufacturer || {
    name: 'Létrehozás',
  };

  return (
    <Box>
      <Flex alignItems="flex-start" mb={2}>
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/manufacturers">
              Gyártók
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex flex="1" />
        <Stack isInline>
          {id !== 'new' && (
            <Button
              variantColor="blue"
              onClick={async () => {
                try {
                  await _delete({ variables: { id } });
                  navigate(`../`);
                  toast({
                    title: 'Sikeres törlés!',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                } catch (e) {
                  toast({
                    title: 'Sikertelen törlés!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                }
              }}
              isLoading={deleteResult.loading}
            >
              Törlés
            </Button>
          )}
          <Button
            variantColor="blue"
            type="submit"
            onClick={() => formRef.current.submitForm()}
            isLoading={
              updateResult.loading ||
              uploadResult.loading ||
              createResult.loading
            }
          >
            Mentés
          </Button>
        </Stack>
      </Flex>
      <ManufacturerForm
        ref={formRef}
        data={data?.manufacturer}
        onSubmit={async (values) => {
          const imageToUpload =
            values.image.edited?.file || values.image.original?.file
              ? values.image
              : null;

          let uploadData;

          if (imageToUpload) {
            const { data } = await upload({
              variables: {
                files: [
                  values.image.edited?.file || values.image.original?.file,
                ],
              },
            });

            uploadData = data;
          }

          const newImage = {
            ...values.image,
            url: uploadData?.upload?.[0].url,
          };

          const data = {
            ...values,
            __typename: undefined,
            image: {
              update:
                values.image.id === newImage.id
                  ? {
                      url: newImage.url,
                    }
                  : {
                      url: values.image.url,
                    },
              create:
                values.image.id !== image?.id
                  ? {
                      url: newImage.url,
                    }
                  : undefined,
            },
          };

          if (id === 'new') {
            const result = await create({
              variables: {
                data: {
                  ...data,
                  image: data.image.create
                    ? {
                        create: data.image.create,
                      }
                    : undefined,
                },
              },
            });

            navigate(`/manufacturers/${result.data.createOneManufacturer.id}`);
          } else {
            await update({
              variables: {
                id,
                data,
              },
            });
          }

          toast({
            title: 'Sikeres mentés!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        }}
      />
    </Box>
  );
}

export default Manufacturer;
