import { Box, Divider, Flex, Text } from '@chakra-ui/core';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import React from 'react';
import Label from './Label';

const translation = {
  Created: 'Létrehozva',
  'Invoice created': 'Számla létrehozva',
  'Confirmation email sent': 'Visszaigazoló email kiküldve',
  'Status changed to new': 'Új állapotba került',
  'Status changed to ready': 'Átvehető állapotba került',
  'Status changed to paid': 'Fizetve állapotba került',
  'Status changed to cancelled': 'Törölt állapotba került',
  'Status changed to delivered': 'Teljesítve állapotba került',
  'Status changed to shipped': 'Postázva állapotba került',
  'Shipped email sent': 'Kiszállításról email kiküldve',
  'Ready email sent': 'Átvehetőségről email kiküldve',
  'Invoice email sent': 'Számlát tartalmazó email kiküldve',
  'User updated': 'Felhasználó frissítve',
  'Verification email sent': 'Megerősítő email kiküldve',
  'Email verified': 'Email megerősítve',
  'Created from guest': 'Létrehozva vendégből',
  'Password reset email sent': 'Jelszó visszaállító email kiküldve',
  'Password reseted': 'Jelszó visszaállítva',
  'Guest created': 'Vendég létrehozva',
  'Order placed': 'Rendelés leadva',
  'Card payment failed': 'Kártyás fizetés sikertelen',
  'Payment failed email sent': 'Sikertelen fizetésről email kiküldve',
  'Payment method changed to cash': 'Fizetési mód készpénzre változott',
  'Payment method changed to transfer': 'Fizetési mód átutalásra változott',
  'Payment method changed to card': 'Fizetési mód bankkártyára változott',
};

const t = (message) => {
  return translation[message] || message;
};

function Timeline({ data }) {
  if (!data.length) {
    return (
      <Box textAlign="center" py={4}>
        <Label>Nincs tárolt aktivitás</Label>
      </Box>
    );
  }

  return (
    <Box>
      {data?.map(({ id, message, createdAt }) => (
        <Flex key={id}>
          <Flex flexDirection="column" alignItems="center" mr={2}>
            <Divider
              orientation="vertical"
              borderLeftWidth="4px"
              flex={1}
              mx={0}
            />
            <Box
              bg="blue.500"
              borderRadius="full"
              width="1.25rem"
              height="1.25rem"
              borderWidth={4}
              borderColor="white"
            />
            <Divider
              orientation="vertical"
              borderLeftWidth="4px"
              flex={1}
              mx={0}
            />
          </Flex>
          <Flex justifyContent="space-between" py={3} flex={1}>
            <Text fontSize="md" mr={2}>
              {t(message)}
            </Text>
            <Text fontSize="sm" color="gray.500" flexShrink={0}>
              {format(new Date(createdAt), 'Pp', { locale: hu })}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Box>
  );
}

export default Timeline;
