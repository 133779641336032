import React from 'react';

function useFormInput(initialValue) {
  const ref = React.useRef();
  const [value, setValue] = React.useState(initialValue);

  function handleChange(e) {
    setValue(e.target.value);
  }

  return {
    ref,
    value,
    onChange: handleChange,
    setValue,
  };
}

export default useFormInput;
