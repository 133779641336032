import React from 'react';
import { Flex, Spinner } from '@chakra-ui/core';

function Loading({ ...rest }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      {...rest}
    >
      <Spinner size="xl" />
    </Flex>
  );
}

export default Loading;
