import React from 'react';
import { Checkbox, CheckboxGroup } from '@chakra-ui/core';
import { gql, useQuery } from '@apollo/client';

const CollectionsQuery = gql`
  query CollectionsQuery {
    collections {
      id
      title
    }
  }
`;

function CollectionsSelect({ value = [], onChange, setValue, name, ...rest }) {
  const { data } = useQuery(CollectionsQuery);

  return (
    <CheckboxGroup
      value={value.map((v) => v.id)}
      onChange={(value) => {
        setValue(value.map((v) => ({ id: v })));
      }}
      ml={2}
      {...rest}
    >
      {data?.collections.map(({ id, title }) => (
        <Checkbox key={id} value={id}>
          {title}
        </Checkbox>
      ))}
    </CheckboxGroup>
  );

  // return (
  //   <Select
  //     isMulti
  //     theme={(t) => ({
  //       ...t,
  //       colors: {
  //         ...t.colors,
  //         primary: theme.colors.blue[500],
  //       },
  //     })}
  //     styles={{
  //       control: (props) => {
  //         return {
  //           ...props,
  //           borderColor: theme.colors.gray[200],
  //           '&:hover': { borderColor: theme.colors.gray[300] },
  //         };
  //       },
  //     }}
  //     options={data?.collections.map(({ id, title }) => ({
  //       value: id,
  //       label: title,
  //     }))}
  //     value={value?.map(({ id }) => ({
  //       value: id,
  //       label: data?.collections.find((c) => c.id === id)?.title,
  //     }))}
  //     onChange={(value) => {
  //       setValue(value?.map(({ value }) => ({ id: value })));
  //     }}
  //     {...rest}
  //   />
  // );
}

export default CollectionsSelect;
