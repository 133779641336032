import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

const Card = styled(Box)``;

Card.defaultProps = {
  p: 4,
  bg: 'white',
  borderRadius: 'md',
  borderWidth: 1,
};

export default Card;
