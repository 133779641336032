import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Brand from '../pages/brands/Brand';
import Brands from '../pages/brands/Brands';
import Collection from '../pages/collections/Collection';
import Collections from '../pages/collections/Collections';
import Login from '../pages/Login';
import Manufacturer from '../pages/manufacturers/Manufacturer';
import Manufacturers from '../pages/manufacturers/Manufacturers';
import NotFound from '../pages/NotFound';
import Order from '../pages/orders/Order';
import Orders from '../pages/orders/Orders';
import Product from '../pages/products/Product';
import Products from '../pages/products/Products';
import ShippingMethod from '../pages/shipping-methods/ShippingMethod';
import ShippingMethods from '../pages/shipping-methods/ShippingMethods';
import User from '../pages/users/User';
import Users from '../pages/users/Users';
import Home from '../pages/index';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<Product />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:id" element={<Order />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/:id" element={<User />} />
      <Route path="/manufacturers" element={<Manufacturers />} />
      <Route path="/manufacturers/:id" element={<Manufacturer />} />
      <Route path="/brands" element={<Brands />} />
      <Route path="/brands/:id" element={<Brand />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/collections/:id" element={<Collection />} />
      <Route path="/shipping-methods" element={<ShippingMethods />} />
      <Route path="/shipping-methods/:id" element={<ShippingMethod />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
