import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  Stack,
  Tag,
  useToast,
} from '@chakra-ui/core';
import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import Label from '../../components/Label';
import Loading from '../../components/Loading';
import SidebarLayout, { Main, Sidebar } from '../../components/SidebarLayout';
import Timeline from '../../components/Timeline';
import UserForm from './UserForm';

const UserFragment = gql`
  fragment UserFragment on User {
    id
    name
    email
    verified
    createdAt
    role
    orders {
      id
    }
    timeline(orderBy: { createdAt: desc }) {
      id
      createdAt
      message
    }
  }
`;

const UserQuery = gql`
  query UserQuery($id: String!) {
    user(where: { id: $id }) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

const UpdateUserMutation = gql`
  mutation UpdateUserMutation($id: String!, $data: UserUpdateInput!) {
    updateOneUser(where: { id: $id }, data: $data) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

const SendEmailVerificationAdminMutation = gql`
  mutation SendEmailVerificationAdminMutation($userId: String!) {
    sendEmailVerificationAdmin(userId: $userId) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

function User() {
  const params = useParams();
  const { data, loading } = useQuery(UserQuery, {
    variables: { id: params.id },
  });
  const toast = useToast();
  const formRef = useRef();
  const [edit, setEdit] = useState(false);
  const [update, updateResult] = useMutation(UpdateUserMutation);
  const [
    sendEmailVerificationAdmin,
    sendEmailVerificationAdminResult,
  ] = useMutation(SendEmailVerificationAdminMutation);

  if (loading) {
    return <Loading />;
  }

  const { id, name, email, verified, orders, role, timeline } = data?.user;

  return (
    <Box>
      <Flex mb={2}>
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/users">
              Felhasználók
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex flex="1" />
        <Stack isInline>
          {edit ? (
            <Button
              variantColor="blue"
              type="submit"
              onClick={() => formRef.current.submitForm()}
              isLoading={updateResult.loading}
            >
              Mentés
            </Button>
          ) : (
            <IconButton
              aria-label="Search database"
              icon="edit"
              onClick={() => setEdit(true)}
            />
          )}
          {verified ? (
            <Tag variantColor="green">Megerősítve</Tag>
          ) : (
            <Tag variantColor="red">Nincs megerősítve</Tag>
          )}
        </Stack>
      </Flex>
      <SidebarLayout>
        <Main>
          <UserForm
            ref={formRef}
            data={data?.user}
            isReadOnly={!edit}
            onSubmit={async (values) => {
              if (values.email === email && values.name === name) {
                setEdit(false);
                return;
              }

              await update({
                variables: {
                  id,
                  data: {
                    ...values,
                    __typename: undefined,
                    timeline: {
                      create: { message: 'User updated' },
                    },
                  },
                },
              });

              setEdit(false);

              toast({
                title: 'Sikeres mentés!',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom',
              });
            }}
          />
          <Box pt={2}>
            <Heading size="md" mb={2} px={2}>
              Idővonal
            </Heading>
            <Card>
              <Timeline data={timeline} />
            </Card>
          </Box>
        </Main>
        <Sidebar>
          <Button
            as={Link}
            to={{ pathname: '/orders', search: `?q=${id}` }}
            variantColor="blue"
          >
            Rendelések
            <Badge
              variantColor="blue"
              borderRadius="full"
              position="absolute"
              top="-0.25rem"
              right="-0.25rem"
              height={8}
              minWidth="2rem"
              fontSize="md"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderWidth="2px"
              borderColor="gray.50"
            >
              {orders.length}
            </Badge>
          </Button>
          <Box pt={2}>
            <Heading size="md" mb={2} px={2}>
              Haladó
            </Heading>
            <Card>
              <Box position="relative">
                <Flex alignItems="center" justifyContent="space-between">
                  <Label>Email cím megerősítő</Label>
                  <Button
                    size="xs"
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      const r = window.confirm(
                        'Biztosan szeretnéd újraküldeni?'
                      );
                      if (r === true) {
                        await sendEmailVerificationAdmin({
                          variables: { userId: id },
                        });
                      }
                    }}
                    isLoading={sendEmailVerificationAdminResult.loading}
                    isDisabled={role === 'GUEST' || verified === true}
                  >
                    Újraküldés
                  </Button>
                </Flex>
              </Box>
            </Card>
          </Box>
        </Sidebar>
      </SidebarLayout>
    </Box>
  );
}

export default User;
