import React from 'react';
import { Box, useColorMode } from '@chakra-ui/core';

const Container = (props) => {
  const { colorMode } = useColorMode();

  const bgColor = { light: 'gray.50', dark: 'gray.800' };
  const color = { light: 'gray.800', dark: 'whiteAlpha.900' };

  return <Box bg={bgColor[colorMode]} color={color[colorMode]} {...props} />;
};

export default Container;
