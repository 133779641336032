const { gql } = require('@apollo/client');

export const UploadFileMutation = gql`
  mutation UploadFileMutation($files: [Upload!]!) {
    upload(files: $files) {
      url
    }
  }
`;

export const ProfileQuery = gql`
  query ProfileQuery {
    me {
      id
      name
      role
      email
    }
  }
`;

export const LogoutMutation = gql`
  mutation LogoutMutation {
    logout
  }
`;

export const LoggedInQuery = gql`
  {
    loggedIn @client
  }
`;
