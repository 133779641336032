import React from 'react';
import { Box, Heading } from '@chakra-ui/core';

function StoreBrand({ fontSize = 'xl', ...rest }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      px={4}
      height="100%"
      fontSize={fontSize}
      {...rest}
    >
      <Heading fontSize="inherit" color="blue.400">
        Diecast
      </Heading>
      <Heading fontSize="inherit" color="red.400">
        Garage
      </Heading>
    </Box>
  );
}

export default StoreBrand;
