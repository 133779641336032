import React, { useRef, useState, useCallback } from 'react';
import {
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from '@chakra-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import throttle from 'lodash.throttle';

const cropImage = async (image, crop) => {
  const canvas = document.createElement('canvas');

  const { naturalWidth, naturalHeight } = image;

  const ctx = canvas.getContext('2d');

  const scaledCrop = {
    x: (crop.x / 100) * naturalWidth,
    y: (crop.y / 100) * naturalHeight,
    width: (crop.width / 100) * naturalWidth,
    height: (crop.height / 100) * naturalHeight,
  };

  canvas.width = scaledCrop.width;
  canvas.height = scaledCrop.height;

  ctx.drawImage(
    image,
    scaledCrop.x,
    scaledCrop.y,
    scaledCrop.width,
    scaledCrop.height,
    0,
    0,
    scaledCrop.width,
    scaledCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

function EditableImage({ value, setValue, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const imageRef = useRef();

  const [crop, setCrop] = useState({ aspect: 4 / 3 });

  const makeClientCrop = async () => {
    try {
      const file = await cropImage(imageRef.current, crop);

      if (file) {
        const preview = window.URL.createObjectURL(file);

        setValue({
          ...value,
          edited: {
            preview,
            file,
          },
        });
      } else {
        setValue({
          ...value,
          edited: undefined,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('TODO');
    }
  };

  const handleChange = useCallback(
    throttle((c, percentCrop) => setCrop(percentCrop), 15),
    []
  );

  return (
    <>
      <Image
        {...rest}
        onLoad={() => setLoading(false)}
        onClick={onOpen}
        ignoreFallback
      />
      {loading && (
        <Spinner
          size="xl"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          margin="auto"
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Kép szerkesztése</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReactCrop
              src={value.original?.preview || value.url}
              onImageLoaded={(img) => {
                imageRef.current = img;
              }}
              crop={crop}
              ruleOfThirds
              onChange={handleChange}
              crossorigin="anonymous"
            />
          </ModalBody>

          <ModalFooter>
            <Button variantColor="red" mr={3} onClick={onClose}>
              Elvetés
            </Button>
            <Button
              variantColor="blue"
              onClick={() => {
                makeClientCrop();
                onClose();
              }}
            >
              Mentés
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditableImage;
