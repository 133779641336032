import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Button,
  useToast,
  Stack,
} from '@chakra-ui/core';
import React, { useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import CollectionForm from './CollectionForm';
import { invalidateCachedItems } from '../../components/DataTable';

const CollectionFragment = gql`
  fragment CollectionFragment on Collection {
    id
    updatedAt
    title
  }
`;

const CollectionQuery = gql`
  query CollectionQuery($id: String!) {
    collection(where: { id: $id }) {
      ...CollectionFragment
    }
  }
  ${CollectionFragment}
`;

const UpdateCollectionMutation = gql`
  mutation UpdateCollectionMutation(
    $id: String!
    $data: CollectionUpdateInput!
  ) {
    updateOneCollection(where: { id: $id }, data: $data) {
      ...CollectionFragment
    }
  }
  ${CollectionFragment}
`;

const CreateCollectionMutation = gql`
  mutation CreateCollectionMutation($data: CollectionCreateInput!) {
    createOneCollection(data: $data) {
      id
    }
  }
`;

const DeleteCollectionMutation = gql`
  mutation DeleteCollectionMutation($id: String!) {
    deleteOneCollection(where: { id: $id }) {
      id
    }
  }
`;

function Collection() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const formRef = useRef();

  const { data, loading } = useQuery(CollectionQuery, {
    variables: { id },
    skip: id === 'new',
  });

  const [update, updateResult] = useMutation(UpdateCollectionMutation);
  const [_delete, deleteResult] = useMutation(DeleteCollectionMutation, {
    update: invalidateCachedItems,
  });

  const [create, createResult] = useMutation(CreateCollectionMutation, {
    update: invalidateCachedItems,
  });

  if (loading) {
    return <Loading />;
  }

  const { title } = data?.collection || {
    title: 'Létrehozás',
  };

  return (
    <Box>
      <Flex alignItems="flex-start" mb={2}>
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/collections">
              Kollekciók
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{title}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex flex="1" />
        <Stack isInline>
          {id !== 'new' && (
            <Button
              variantColor="blue"
              onClick={async () => {
                try {
                  await _delete({ variables: { id } });
                  navigate(`../`);
                  toast({
                    title: 'Sikeres törlés!',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                } catch (e) {
                  toast({
                    title: 'Sikertelen törlés!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                }
              }}
              isLoading={deleteResult.loading}
            >
              Törlés
            </Button>
          )}
          <Button
            variantColor="blue"
            type="submit"
            onClick={() => formRef.current.submitForm()}
            isLoading={updateResult.loading || createResult.loading}
          >
            Mentés
          </Button>
        </Stack>
      </Flex>
      <CollectionForm
        ref={formRef}
        data={data?.collection}
        onSubmit={async (values) => {
          const data = {
            ...values,
          };

          if (id === 'new') {
            const result = await create({
              variables: {
                data: {
                  ...data,
                },
              },
            });

            navigate(`/collections/${result.data.createOneCollection.id}`);
          } else {
            await update({
              variables: {
                id,
                data: {
                  ...data,
                  __typename: undefined,
                },
              },
            });
          }

          toast({
            title: 'Sikeres mentés!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        }}
      />
    </Box>
  );
}

export default Collection;
