import { useEffect, useRef } from 'react';

function useAutoSelect({ possibleValues, items, touched, value, setValue }) {
  const lastAutoSelected = useRef('');

  useEffect(() => {
    if (possibleValues && !touched) {
      const match = items?.find(({ name }) => possibleValues.includes(name));

      const newValue = match?.id || '';

      if (lastAutoSelected.current === value && value !== newValue) {
        lastAutoSelected.current = newValue;
        setValue(newValue);
      }
    }
  }, [items, possibleValues, setValue, touched, value]);
}

export default useAutoSelect;
