import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ColorModeProvider, CSSReset, ThemeProvider } from '@chakra-ui/core';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthGuard from './AuthGuard';
import Layout from './Layout';
import Router from './Router';
import theme from '../theme';

console.log(theme);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider value="light">
        <CSSReset />
        <ApolloProvider client={client}>
          <BrowserRouter>
            <AuthGuard>
              <Layout>
                <Router />
              </Layout>
            </AuthGuard>
          </BrowserRouter>
        </ApolloProvider>
      </ColorModeProvider>
    </ThemeProvider>
  );
}

export default App;
