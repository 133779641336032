import React from 'react';
import { Select } from '@chakra-ui/core';
import { gql, useQuery } from '@apollo/client';
import useAutoSelect from './useAutoSelect';

const BrandsQuery = gql`
  query BrandsQuery {
    brands {
      id
      name
    }
  }
`;

function BrandSelect({ ...rest }) {
  const { data } = useQuery(BrandsQuery);

  useAutoSelect({ items: data?.brands, ...rest });

  return (
    <Select {...rest}>
      <option value="" disabled>
        Kérlek válassz
      </option>
      {data?.brands.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
}

export default BrandSelect;
