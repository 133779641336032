import { NetworkStatus, useQuery } from '@apollo/client';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Button,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import uniqBy from 'lodash.uniqby';
import useFormInput from '../hooks/useFormInput';
import Label from './Label';
import GridTable, { Cell as BaseCell, Row as BaseRow } from './GridTable';
import useFetchMore from '../hooks/useFetchMore';

export function Row({ ...rest }) {
  return <BaseRow role="group" {...rest} />;
}

export function Cell({ label, children, ...rest }) {
  return (
    <BaseCell
      _notFirst={{ pt: label && [2, 4] }}
      transition="all 0.2s"
      _groupHover={{
        transform: 'translateX(0.25rem)',
        fontWeight: 'medium',
      }}
      {...rest}
    >
      {label && <Label display={['block', 'none']}>{label}</Label>}
      {children}
    </BaseCell>
  );
}

const Info = styled(Flex)``;

Info.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
};

export function invalidateCachedItems(cache, payload) {
  const { __typename: typeName } = Object.keys(payload.data)[0];

  Object.keys(cache.data.data).forEach((key) => {
    if (key.match(new RegExp(typeName))) {
      cache.data.delete(key);
    }
  });
}

function DataTable({
  children,
  title,
  query,
  templateColumns,
  newLink,
  transformVariables = (v) => v,
  renderFiltering,
  ...rest
}) {
  const navigate = useNavigate();
  const searchParams = useSearchParams();

  const { setValue, ...input } = useFormInput(searchParams.get('q') || '');
  const [variables, setVariables] = useState({
    first: 10,
    query: input.value,
  });

  const [buttonRef, setButtonRef] = useState();
  const [cursor, setCursor] = useState();

  const { data, loading, networkStatus, fetchMore } = useQuery(query, {
    variables: transformVariables(variables),
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreProducts = useCallback(() => {
    try {
      fetchMore({
        variables: {
          after: {
            id: cursor,
          },
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          const c = fetchMoreResult.items[fetchMoreResult.items.length - 1]?.id;

          if (c && !previous.items.find((p) => p.id === c)) {
            setCursor(c);
          } else {
            setCursor(null);
          }

          return {
            ...previous,
            items: uniqBy([...previous.items, ...fetchMoreResult.items], 'id'),
          };
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [cursor, fetchMore]);

  useFetchMore(buttonRef, {
    fetchMore: fetchMoreProducts,
    cursor,
  });

  useEffect(() => {
    if (cursor === undefined) {
      setCursor(data?.items[data?.items.length - 1]?.id);
    }
  }, [data, cursor]);

  useEffect(() => {
    const query = searchParams.get('q') || '';

    if (variables.query !== query) {
      setValue(query);
      setVariables((variables) => ({ ...variables, query }));
    }
  }, [searchParams, setValue, variables.query]);

  return (
    <Box {...rest}>
      <Flex mb={2}>
        <InputGroup width="100%" maxWidth="xs" mr={4}>
          <InputLeftElement>
            <Icon name="search" color="gray.400" />
          </InputLeftElement>
          <Input
            {...input}
            variant="filled"
            placeholder="Keresés"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                input.ref.current.blur();
              }
            }}
            onBlur={() => {
              if (input.value) {
                searchParams.set('q', input.value);
              } else {
                searchParams.delete('q');
              }

              const q = searchParams.toString();

              navigate({ search: q ? `?${q}` : '' });

              setVariables({ ...variables, query: input.value });
            }}
          />
        </InputGroup>
        {renderFiltering && renderFiltering({ variables, setVariables })}
        <Flex flex="1" />
        <Stack isInline alignItems="center" spacing={4}>
          {(data?.count || data?.items.length) && (
            <Label>{data?.count || data?.items.length}</Label>
          )}
          {newLink && <IconButton icon="add" as={Link} to={newLink} />}
        </Stack>
      </Flex>
      <GridTable
        position="relative"
        bg={data?.items.length ? 'gray.200' : 'gray.50'}
        templateColumns={templateColumns}
        minHeight={!data?.items.length && 40}
      >
        {loading &&
          (networkStatus === NetworkStatus.loading ||
            networkStatus === NetworkStatus.setVariables) && (
            <Info>
              <Spinner size="xl" color="gray.600" />
            </Info>
          )}
        {data?.items.length === 0 && (
          <Info>
            <Label>Nincs találat</Label>
          </Info>
        )}
        {data?.items && children(data.items)}
      </GridTable>
      <Box textAlign="center" mt={2}>
        {cursor && (
          <Button
            ref={(r) => setButtonRef(r)}
            onClick={fetchMoreProducts}
            isLoading={loading}
          >
            További elemek
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default DataTable;
