import { theme as defaultTheme } from '@chakra-ui/core';

const theme = {
  ...defaultTheme,
  fonts: {
    ...defaultTheme.fonts,
    body: defaultTheme.fonts.body.replace('BlinkMacSystemFont, ', ''),
    heading: defaultTheme.fonts.heading.replace('BlinkMacSystemFont, ', ''),
  },
  sizes: {
    ...defaultTheme.sizes,
    header: defaultTheme.sizes[16],
  },
};

export default theme;
