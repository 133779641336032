import { useEffect, useState, useRef } from 'react';

function useFetchMore(buttonRef, { fetchMore, cursor }) {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: '128px',
      }
    );
    if (buttonRef) {
      observer.observe(buttonRef);
    }
    return () => {
      if (buttonRef) {
        observer.unobserve(buttonRef);
      }
    };
  }, [buttonRef]);

  useEffect(() => {
    let timeout = ref.current;

    if (isIntersecting && cursor) {
      timeout = setTimeout(() => fetchMore(), 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [cursor, fetchMore, isIntersecting]);
}

export default useFetchMore;
