import { Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

const Placeholder = styled(Text)``;

Placeholder.defaultProps = {
  fontSize: 'sm',
  color: 'gray.600',
};

export default Placeholder;
