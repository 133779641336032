import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/core';
import { useFormikContext, useField } from 'formik';
import React, { useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';

function GeneratedInput({ getGeneratedValue, ...rest }) {
  const [{ value }, { touched }, { setValue }] = useField(rest);
  const { values } = useFormikContext();
  const lastGeneratedValue = useRef('');

  const setGeneratedValue = useCallback(
    debounce((value) => {
      lastGeneratedValue.current = value;
      setValue(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (!touched) {
      const newValue = getGeneratedValue(values);

      if (lastGeneratedValue.current === value && value !== newValue) {
        setGeneratedValue(newValue);
      }
    }
  }, [getGeneratedValue, setGeneratedValue, touched, value, values]);

  return (
    <InputGroup size="md">
      <Input pr={10} {...rest} />
      <InputRightElement>
        <IconButton
          variant="ghost"
          icon="settings"
          size="sm"
          onClick={() => {
            const newValue = getGeneratedValue(values);
            lastGeneratedValue.current = newValue;
            setValue(newValue);
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
}

export default GeneratedInput;
