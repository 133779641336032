import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { useRef } from 'react';
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import { Field } from '../../components/Form';
import Label from '../../components/Label';
import Loading from '../../components/Loading';
import Action from '../../components/order/Action';
import OrderSummary from '../../components/OrderSummary';
import SidebarLayout, { Main, Sidebar } from '../../components/SidebarLayout';
import Timeline from '../../components/Timeline';
import Value from '../../components/Value';
import Address from './Address';
import { getStatusColor, getStatusText } from './Orders';

export function getPaymentMethodText(paymentMethod) {
  switch (paymentMethod) {
    case 'CARD':
      return 'Bankkártya';
    case 'TRANSFER':
      return 'Átutalás';
    case 'CASH':
      return 'Készpénz';
    default:
      return paymentMethod;
  }
}

const OrderFragment = gql`
  fragment OrderFragment on Order {
    id
    amount
    paymentMethod
    transactionId
    invoiceId
    trackingNumber
    shippingMethod {
      name
      description
      price
      type
    }
    status
    note
    user {
      id
      name
      email
      orders {
        id
      }
    }
    items {
      id
      title
      type
      product {
        id
        images {
          url
        }
      }
      shippingMethod {
        id
      }
      quantity
      price
    }
    billingAddress {
      id
      name
      line
      city
      zip
      country
      vatNumber
      note
    }
    shippingAddress {
      id
      name
      line
      city
      zip
      country
      phone
      email
      data
      note
    }
    timeline(orderBy: { createdAt: desc }) {
      id
      createdAt
      message
    }
  }
`;

const OrderQuery = gql`
  query OrderQuery($id: String!) {
    order(where: { id: $id }) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

const SendShippedNotificationMutation = gql`
  mutation SendShippedNotificationMutation($orderId: String!) {
    sendShippedNotification(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

const SendReadyNotificationMutation = gql`
  mutation SendReadyNotificationMutation($orderId: String!) {
    sendReadyNotification(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

const SendPaymentFailedNotificationMutation = gql`
  mutation SendPaymentFailedNotificationMutation($orderId: String!) {
    sendPaymentFailedNotification(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

const UpdateOrderMutation = gql`
  mutation UpdateOrderMutation($id: String!, $data: OrderUpdateInput!) {
    updateOneOrder(where: { id: $id }, data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

const CreateInvoiceMutation = gql`
  mutation CreateInvoiceMutation($orderId: String!) {
    invoice(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

const SendOrderConfirmationMutation = gql`
  mutation SendOrderConfirmationMutation($orderId: String!) {
    sendOrderConfirmation(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

function Order() {
  const params = useParams();
  const { data, loading } = useQuery(OrderQuery, {
    variables: { id: params.id },
  });
  const [update, updateResult] = useMutation(UpdateOrderMutation);
  const [sendReadyNotification, sendReadyNotificationResult] = useMutation(
    SendReadyNotificationMutation
  );
  const [sendShippedNotification, sendShippedNotificationResult] = useMutation(
    SendShippedNotificationMutation
  );
  const [
    sendPaymentFailedNotification,
    sendPaymentFailedNotificationResult,
  ] = useMutation(SendPaymentFailedNotificationMutation);
  const [sendOrderConfirmation, sendOrderConfirmationResult] = useMutation(
    SendOrderConfirmationMutation
  );
  const [createInvoice, createInvoiceResult] = useMutation(
    CreateInvoiceMutation
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const ref = useRef();

  if (loading) {
    return <Loading />;
  }

  const {
    id,
    user,
    status,
    shippingAddress,
    billingAddress,
    note,
    paymentMethod,
    shippingMethod,
    transactionId,
    invoiceId,
    trackingNumber,
    timeline,
  } = data?.order;

  const isBillingSame =
    !billingAddress.vatNumber && shippingAddress.line === billingAddress.line;

  return (
    <Box>
      <Flex mb={2}>
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/orders">
              Rendelések
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{id}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex flex="1" />
        <Tag variantColor={getStatusColor(status)}>{getStatusText(status)}</Tag>
      </Flex>

      <SidebarLayout>
        <Main>
          <Box>
            <Heading size="md" mb={2} px={2}>
              Művelet
            </Heading>
            <Card>
              <Action
                order={data?.order}
                update={update}
                result={updateResult}
                sendReadyNotification={sendReadyNotification}
                sendShippedNotification={sendShippedNotification}
                createInvoice={createInvoice}
              />
            </Card>
          </Box>
          <Box pt={2}>
            <Heading size="md" mb={2} px={2}>
              Összesítő
            </Heading>
            <Card p={0} overflow="hidden">
              <OrderSummary data={data?.order} />
            </Card>
          </Box>
          <Box pt={2}>
            <Heading size="md" mb={2} px={2}>
              Idővonal
            </Heading>
            <Card>
              <Timeline data={timeline} />
            </Card>
          </Box>
        </Main>
        <Sidebar>
          <Box>
            <Heading size="md" mb={2} px={2}>
              Megrendelő
            </Heading>
            <Card>
              <Link to={`/users/${user.id}`}>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  position="relative"
                >
                  <Avatar name={user.name} />
                  <Text>{user.name}</Text>
                  <Text>{user.email}</Text>
                  <Icon
                    name="external-link"
                    position="absolute"
                    top={0}
                    right={0}
                  />
                </Flex>
              </Link>

              <Divider my={4} />
              <Address data={shippingAddress} />
              <Divider my={4} />
              <Address
                data={billingAddress}
                isBilling
                isBillingSame={isBillingSame}
              />
            </Card>
          </Box>
          <Box pt={2}>
            <Heading size="md" mb={2} px={2}>
              Adatok
            </Heading>
            <Card>
              <Box mb={2} position="relative">
                <Label>Fizetési mód</Label>
                <Text>{getPaymentMethodText(paymentMethod)}</Text>
                <Button
                  size="xs"
                  onClick={onOpen2}
                  position="absolute"
                  top={0}
                  right={0}
                >
                  Módosítás
                </Button>
                <Modal
                  isOpen={isOpen2}
                  onClose={onClose2}
                  initialFocusRef={ref}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <Formik
                      initialValues={{
                        paymentMethod,
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        if (paymentMethod !== values.paymentMethod) {
                          await update({
                            variables: {
                              id,
                              data: {
                                paymentMethod: values.paymentMethod,
                                timeline: {
                                  create: {
                                    message: `Payment method changed to ${values.paymentMethod.toLowerCase()}`,
                                  },
                                },
                              },
                            },
                          });
                        }
                        setSubmitting(false);
                        onClose2();
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <ModalHeader>Fizetési mód</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Field
                              name="paymentMethod"
                              label="Fizetési mód"
                              as={Select}
                              innerRef={ref}
                            >
                              <option value="CARD">Bankkártya</option>
                              <option value="TRANSFER">Átutalás</option>
                              {shippingMethod.type === 'PICKUP' && (
                                <option value="CASH">Készpénz</option>
                              )}
                            </Field>
                          </ModalBody>

                          <ModalFooter>
                            <Button mr={3} onClick={onClose2}>
                              Mégse
                            </Button>
                            <Button
                              variantColor="blue"
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Mentés
                            </Button>
                          </ModalFooter>
                        </Form>
                      )}
                    </Formik>
                  </ModalContent>
                </Modal>
              </Box>
              <Box mb={2}>
                <Label>Tranzakció azonosító</Label>
                <Value>{transactionId}</Value>
              </Box>
              <Box position="relative">
                <Label>Számla azonosító</Label>
                <Value>{invoiceId}</Value>
                {!invoiceId && (
                  <Button
                    size="xs"
                    onClick={async () => {
                      await createInvoice({ variables: { orderId: id } });
                    }}
                    position="absolute"
                    top={0}
                    right={0}
                    isLoading={createInvoiceResult.loading}
                  >
                    Számla kiállítása
                  </Button>
                )}
              </Box>
              <Divider my={4} />
              <Box position="relative">
                <Label>Csomagkövetési szám</Label>
                <Button
                  size="xs"
                  onClick={onOpen}
                  position="absolute"
                  top={0}
                  right={0}
                >
                  Módosítás
                </Button>
                <Value>{trackingNumber}</Value>
                <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={ref}>
                  <ModalOverlay />
                  <ModalContent>
                    <Formik
                      initialValues={{
                        trackingNumber,
                      }}
                      onSubmit={async (
                        { trackingNumber },
                        { setSubmitting }
                      ) => {
                        await update({
                          variables: {
                            id,
                            data: {
                              trackingNumber,
                              timeline: {
                                create: { message: 'Tracking number updated' },
                              },
                            },
                          },
                        });
                        setSubmitting(false);
                        onClose();
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <ModalHeader>Csomagkövetési szám</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Field
                              name="trackingNumber"
                              label="Csomagkövetési szám"
                              innerRef={ref}
                            />
                          </ModalBody>

                          <ModalFooter>
                            <Button mr={3} onClick={onClose}>
                              Mégse
                            </Button>
                            <Button
                              variantColor="blue"
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Mentés
                            </Button>
                          </ModalFooter>
                        </Form>
                      )}
                    </Formik>
                  </ModalContent>
                </Modal>
              </Box>
              <Divider my={4} />
              <Formik
                initialValues={{
                  note: note || '',
                }}
                onSubmit={async ({ note }) => {
                  if (note !== data.order.note) {
                    await update({
                      variables: {
                        id,
                        data: {
                          note,
                        },
                      },
                    });
                  }
                }}
              >
                {({ submitForm }) => (
                  <Form>
                    <Field
                      name="note"
                      label="Megjegyzés"
                      as={Textarea}
                      onBlur={submitForm}
                      variant="filled"
                      placeholder="Megjegyzés..."
                    />
                  </Form>
                )}
              </Formik>
            </Card>
          </Box>
          <Box pt={2}>
            <Heading size="md" mb={2} px={2}>
              Haladó
            </Heading>
            <Card>
              <Box position="relative">
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Label>Visszaigazoló email</Label>
                  <Button
                    size="xs"
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      const r = window.confirm(
                        'Biztosan szeretnéd újraküldeni?'
                      );
                      if (r === true) {
                        await sendOrderConfirmation({
                          variables: { orderId: id },
                        });
                      }
                    }}
                    isLoading={sendOrderConfirmationResult.loading}
                  >
                    Újraküldés
                  </Button>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Label>Számlát tartalmazó email</Label>
                  <Button
                    size="xs"
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      const r = window.confirm(
                        'Biztosan szeretnéd újraküldeni?'
                      );
                      if (r === true) {
                        await createInvoice({
                          variables: { orderId: id },
                        });
                      }
                    }}
                    isLoading={createInvoiceResult.loading}
                  >
                    Újraküldés
                  </Button>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Label>Kiszállításról email</Label>
                  <Button
                    size="xs"
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      const r = window.confirm(
                        'Biztosan szeretnéd újraküldeni?'
                      );
                      if (r === true) {
                        await sendShippedNotification({
                          variables: { orderId: id },
                        });
                      }
                    }}
                    isLoading={sendShippedNotificationResult.loading}
                    isDisabled={shippingMethod.type === 'PICKUP'}
                  >
                    Újraküldés
                  </Button>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Label>Átvehetőségről email</Label>
                  <Button
                    size="xs"
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      const r = window.confirm(
                        'Biztosan szeretnéd újraküldeni?'
                      );
                      if (r === true) {
                        await sendReadyNotification({
                          variables: { orderId: id },
                        });
                      }
                    }}
                    isLoading={sendReadyNotificationResult.loading}
                    isDisabled={shippingMethod.type !== 'PICKUP'}
                  >
                    Újraküldés
                  </Button>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Label>Sikertelen fizetés email</Label>
                  <Button
                    size="xs"
                    onClick={async () => {
                      // eslint-disable-next-line no-alert
                      const r = window.confirm('Biztosan szeretnéd kiküldeni?');
                      if (r === true) {
                        await sendPaymentFailedNotification({
                          variables: { orderId: id },
                        });
                      }
                    }}
                    isLoading={sendPaymentFailedNotificationResult.loading}
                    isDisabled={!(paymentMethod === 'CARD' && status === 'NEW')}
                  >
                    Küldés
                  </Button>
                </Flex>
              </Box>
            </Card>
          </Box>
        </Sidebar>
      </SidebarLayout>
    </Box>
  );
}

export default Order;
