import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const ShippingMethodsQuery = gql`
  query ShippingMethodsQuery(
    $first: Int!
    $after: ShippingMethodWhereUniqueInput
    $query: String
  ) {
    items: shippingMethods(
      first: $first
      after: $after
      where: { name: { contains: $query } }
    ) {
      id
      name
      price
      description
    }
    count: shippingMethodsCount(where: { name: { contains: $query } })
  }
`;

function ShippingMethods() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Szállítási módok</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable
        templateColumns={['1fr', '1fr 1fr 1fr']}
        query={ShippingMethodsQuery}
        newLink="/shipping-methods/new"
      >
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell>
                <Label>Név</Label>
              </Cell>
              <Cell>
                <Label>Leírás</Label>
              </Cell>
              <Cell textAlign={['left', 'right']}>
                <Label>Ár</Label>
              </Cell>
            </Row>
            {items.map(({ id, name, description, price }) => (
              <Row key={id} as={Link} to={`/shipping-methods/${id}`}>
                <Cell label="Név">{name}</Cell>
                <Cell label="Leírás">{description}</Cell>
                <Cell label="Ár" textAlign={['left', 'right']}>
                  {price} Ft
                </Cell>
              </Row>
            ))}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default ShippingMethods;
