import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Button,
  useToast,
  Stack,
} from '@chakra-ui/core';
import React, { useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import ShippingMethodForm from './ShippingMethodForm';
import { invalidateCachedItems } from '../../components/DataTable';

const ShippingMethodFragment = gql`
  fragment ShippingMethodFragment on ShippingMethod {
    id
    updatedAt
    name
    price
    vat
    description
    type
  }
`;

const ShippingMethodQuery = gql`
  query ShippingMethodQuery($id: String!) {
    shippingMethod(where: { id: $id }) {
      ...ShippingMethodFragment
    }
  }
  ${ShippingMethodFragment}
`;

const UpdateShippingMethodMutation = gql`
  mutation UpdateShippingMethodMutation(
    $id: String!
    $data: ShippingMethodUpdateInput!
  ) {
    updateOneShippingMethod(where: { id: $id }, data: $data) {
      ...ShippingMethodFragment
    }
  }
  ${ShippingMethodFragment}
`;

const CreateShippingMethodMutation = gql`
  mutation CreateShippingMethodMutation($data: ShippingMethodCreateInput!) {
    createOneShippingMethod(data: $data) {
      id
    }
  }
`;

const DeleteShippingMethodMutation = gql`
  mutation DeleteShippingMethodMutation($id: String!) {
    deleteOneShippingMethod(where: { id: $id }) {
      id
    }
  }
`;

function ShippingMethod() {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const formRef = useRef();

  const { data, loading } = useQuery(ShippingMethodQuery, {
    variables: { id },
    skip: id === 'new',
  });

  const [update, updateResult] = useMutation(UpdateShippingMethodMutation);
  const [_delete, deleteResult] = useMutation(DeleteShippingMethodMutation, {
    update: invalidateCachedItems,
  });
  const [create, createResult] = useMutation(CreateShippingMethodMutation, {
    update: invalidateCachedItems,
  });

  if (loading) {
    return <Loading />;
  }

  const { name } = data?.shippingMethod || {
    name: 'Létrehozás',
  };

  return (
    <Box>
      <Flex alignItems="flex-start" mb={2}>
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/shipping-methods">
              Szállítási módok
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex flex="1" />
        <Stack isInline>
          {id !== 'new' && (
            <Button
              variantColor="blue"
              onClick={async () => {
                try {
                  await _delete({ variables: { id } });
                  navigate(`../`);
                  toast({
                    title: 'Sikeres törlés!',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                } catch (e) {
                  toast({
                    title: 'Sikertelen törlés!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom',
                  });
                }
              }}
              isLoading={deleteResult.loading}
            >
              Törlés
            </Button>
          )}
          <Button
            variantColor="blue"
            type="submit"
            onClick={() => formRef.current.submitForm()}
            isLoading={updateResult.loading || createResult.loading}
          >
            Mentés
          </Button>
        </Stack>
      </Flex>
      <ShippingMethodForm
        ref={formRef}
        data={data?.shippingMethod}
        onSubmit={async (values) => {
          const data = {
            ...values,
          };

          if (id === 'new') {
            const result = await create({
              variables: {
                data: {
                  ...data,
                },
              },
            });

            navigate(
              `/shipping-methods/${result.data.createOneShippingMethod.id}`
            );
          } else {
            await update({
              variables: {
                id,
                data: {
                  __typename: undefined,
                  ...data,
                },
              },
            });
          }

          toast({
            title: 'Sikeres mentés!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        }}
      />
    </Box>
  );
}

export default ShippingMethod;
