import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
} from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const ProductsQuery = gql`
  query ProductsQuery(
    $first: Int!
    $after: ProductWhereUniqueInput
    $keyword0: String
    $keyword1: String
    $keyword2: String
    $keyword3: String
    $keyword4: String
    $keyword5: String
    $keyword6: String
    $keyword7: String
    $keyword8: String
    $keyword9: String
  ) {
    items: products(
      first: $first
      after: $after
      where: {
        AND: [
          { keywords: { contains: $keyword0 } }
          { keywords: { contains: $keyword1 } }
          { keywords: { contains: $keyword2 } }
          { keywords: { contains: $keyword3 } }
          { keywords: { contains: $keyword4 } }
          { keywords: { contains: $keyword5 } }
          { keywords: { contains: $keyword6 } }
          { keywords: { contains: $keyword7 } }
          { keywords: { contains: $keyword8 } }
          { keywords: { contains: $keyword9 } }
        ]
      }
    ) {
      id
      title
      price
      images(where: { index: { equals: 0 } }) {
        url
      }
      quantity
    }
    count: productsCount(
      where: {
        AND: [
          { keywords: { contains: $keyword0 } }
          { keywords: { contains: $keyword1 } }
          { keywords: { contains: $keyword2 } }
          { keywords: { contains: $keyword3 } }
          { keywords: { contains: $keyword4 } }
          { keywords: { contains: $keyword5 } }
          { keywords: { contains: $keyword6 } }
          { keywords: { contains: $keyword7 } }
          { keywords: { contains: $keyword8 } }
          { keywords: { contains: $keyword9 } }
        ]
      }
    )
  }
`;

function Products() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Termékek</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable
        templateColumns={['1fr', '5rem 1fr 1fr auto']}
        query={ProductsQuery}
        newLink="/products/new"
        transformVariables={({ query, ...rest }) => {
          const keywords = query?.toLowerCase().split(' ') || [];

          return {
            ...rest,
            keyword0: keywords[0] || undefined,
            keyword1: keywords[1] || undefined,
            keyword2: keywords[2] || undefined,
            keyword3: keywords[3] || undefined,
            keyword4: keywords[4] || undefined,
            keyword5: keywords[5] || undefined,
            keyword6: keywords[6] || undefined,
            keyword7: keywords[7] || undefined,
            keyword8: keywords[8] || undefined,
            keyword9: keywords[9] || undefined,
          };
        }}
      >
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell gridColumn="1/3">
                <Label>Termék</Label>
              </Cell>
              <Cell>
                <Label>Mennyiség</Label>
              </Cell>
              <Cell textAlign="right">
                <Label>Ár</Label>
              </Cell>
            </Row>
            {items.map(({ id, title, quantity, price, images }) => (
              <Row key={id} as={Link} to={`/products/${id}`}>
                <Cell label="Termék">
                  <Image
                    src={images[0]?.url}
                    objectFit="contain"
                    width="5rem"
                  />
                </Cell>
                <Cell>{title}</Cell>
                <Cell label="Mennyiség">{quantity}</Cell>
                <Cell label="Ár" textAlign={['left', 'right']}>
                  {price} Ft
                </Cell>
              </Row>
            ))}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default Products;
