import { Image, Text } from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatPrice } from '../utils/format';
import GridTable, { Cell, Row } from './GridTable';
import Label from './Label';

function OrderSummary({ data: { items, amount } }) {
  return (
    <GridTable templateColumns={{ base: '1fr', sm: 'auto 1fr auto auto' }}>
      <Row display={{ base: 'none', sm: 'contents' }}>
        <Cell gridColumn="1/3">
          <Label>Megnevezés</Label>
        </Cell>
        <Cell textAlign="right">
          <Label>Mennyiség</Label>
        </Cell>
        <Cell textAlign="right">
          <Label>Ár</Label>
        </Cell>
      </Row>
      {items.map((item) => {
        if (item.type === 'PRODUCT') {
          return (
            <Row key={item.id} as={Link} to={`/products/${item.product.id}`}>
              <Cell label="Termék">
                <Image src={item.product.images[0]?.url} height={10} />
              </Cell>
              <Cell>{item.title}</Cell>

              <Cell label="Mennyiség">
                <Text>{item.quantity}</Text>
              </Cell>
              <Cell label="Ár" textAlign={['left', 'left', 'right']}>
                {formatPrice(item.price * item.quantity)}
              </Cell>
            </Row>
          );
        }

        if (item.type === 'SHIPPING_METHOD') {
          return (
            <Row
              key={item.id}
              as={Link}
              to={`/shipping-methods/${item.shippingMethod.id}`}
            >
              <Cell label="Szállítás" gridColumn="1/4">
                {item.title}
              </Cell>
              <Cell label="Ár" textAlign={['left', 'left', 'right']}>
                {formatPrice(item.price)}
              </Cell>
            </Row>
          );
        }

        return null;
      })}
      <Row>
        <Cell
          gridColumn="1/4"
          textAlign={['left', 'left', 'right']}
          fontWeight="semibold"
          fontSize="lg"
        >
          Összesen
        </Cell>
        <Cell
          textAlign={['left', 'left', 'right']}
          fontWeight="semibold"
          fontSize="lg"
        >
          {formatPrice(amount)}
        </Cell>
      </Row>
    </GridTable>
  );
}

export default OrderSummary;
