import React, { useRef, useEffect } from 'react';
import { FieldArray } from 'formik';
import { Flex, Stack, IconButton, Box, Select } from '@chakra-ui/core';
import Field from './Field';

function useAutoAttribute({ possibleValues, touched, value, setValue }) {
  const lastAutoValue = useRef([]);

  useEffect(() => {
    if (possibleValues && !touched) {
      let newValue = value;

      [
        {
          key: 'scale',
          compareFn: (v) => v.startsWith('1:'),
        },
        {
          key: 'color',
          compareFn: (v) => ['fekete', 'piros', 'kék'].includes(v),
        },
        {
          key: 'material',
          compareFn: (v) => ['diecast', 'resin', 'composite'].includes(v),
        },
      ].forEach(({ key, compareFn }, i) => {
        const match = possibleValues?.find(compareFn);

        const index = newValue.findIndex((a) => a.key === key);
        const attribute = newValue[index];

        if (
          lastAutoValue.current[i] === attribute?.value &&
          attribute?.value !== match
        ) {
          lastAutoValue.current[i] = match;

          if (match) {
            if (index === -1) {
              newValue = [
                ...newValue,
                {
                  key,
                  value: match,
                },
              ];
            } else {
              newValue = [
                ...newValue.slice(0, index),
                {
                  key,
                  value: match,
                },
                ...newValue.slice(index + 1),
              ];
            }
          } else {
            newValue = [
              ...newValue.slice(0, index),
              ...newValue.slice(index + 1),
            ];
          }
        }
      });

      if (JSON.stringify(newValue) !== JSON.stringify(value)) {
        setValue(newValue);
      }
    }
  }, [possibleValues, setValue, touched, value]);
}

function AttributesInput(props) {
  useAutoAttribute(props);

  const { value, name, ...rest } = props;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {value?.map((attribute, i) => (
            <Flex key={attribute.id || i} alignItems="flex-start">
              <Field
                name={`${name}[${i}].key`}
                label="Kulcs"
                containerProps={{ width: '100%', mr: 2 }}
                as={Select}
              >
                <option value="" disabled>
                  Kérlek válassz
                </option>
                <option value="scale">Méretarány</option>
                <option value="color">Szín</option>
                <option value="material">Anyag</option>
              </Field>
              <Field
                name={`${name}[${i}].value`}
                label="Érték"
                containerProps={{ width: '100%' }}
              />
              <Flex mt={2} ml={2}>
                <Stack spacing={1}>
                  <IconButton
                    icon="arrow-up"
                    isDisabled={!attribute.id || i === 0}
                    onClick={() => arrayHelpers.swap(i, i - 1)}
                    size="xs"
                  />
                  <IconButton
                    icon="arrow-down"
                    isDisabled={!attribute.id || i === value.length - 1}
                    onClick={() => arrayHelpers.swap(i, i + 1)}
                    size="xs"
                  />
                </Stack>
                <IconButton
                  icon="delete"
                  variantColor="red"
                  onClick={() => arrayHelpers.remove(i)}
                  size="xs"
                  ml={1}
                />
              </Flex>
            </Flex>
          ))}
          <Box>
            <IconButton
              icon="add"
              variantColor="green"
              onClick={() => arrayHelpers.push({ key: '', value: '' })}
              size="xs"
            />
          </Box>
        </>
      )}
      {...rest}
    />
  );
}

export default AttributesInput;
