import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const CollectionsQuery = gql`
  query CollectionsQuery(
    $first: Int!
    $after: CollectionWhereUniqueInput
    $query: String
  ) {
    items: collections(
      first: $first
      after: $after
      where: { title: { contains: $query } }
    ) {
      id
      title
    }
    count: collectionsCount(where: { title: { contains: $query } })
  }
`;

function Collections() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Kollekciók</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable
        templateColumns={['1fr', '1fr']}
        query={CollectionsQuery}
        newLink="/collections/new"
      >
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell>
                <Label>Cím</Label>
              </Cell>
            </Row>
            {items.map(({ id, title }) => (
              <Row key={id} as={Link} to={`/collections/${id}`}>
                <Cell label="Cím">{title}</Cell>
              </Row>
            ))}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default Collections;
