import {
  AspectRatioBox,
  Box,
  Flex,
  Grid,
  IconButton,
  Stack,
} from '@chakra-ui/core';
import cuid from 'cuid';
import { FieldArray } from 'formik';
import React from 'react';
import Field from '../Field';
import EditableImage from './EditableImage';
import FileInput from './FileInput';

function ImageInput({
  name,
  value,
  setValue,
  multiple,
  error,
  onBlur,
  ...rest
}) {
  const handleSelect = async (event) => {
    event.stopPropagation();

    const { files } = event.target;

    if (multiple) {
      setValue([
        ...value,
        ...Array.from(files).map((file) => ({
          id: +new Date() + Math.floor(Math.random() * 100),
          original: {
            preview: window.URL.createObjectURL(file),
            file,
          },
        })),
      ]);
    } else {
      const file = files[0];

      setValue({
        id: cuid(),
        original: {
          preview: window.URL.createObjectURL(file),
          file,
        },
      });
    }
  };

  const hasValue = multiple ? !!value.length : !!value;

  return (
    <Box {...rest}>
      {!hasValue && (
        <FileInput
          onChange={handleSelect}
          multiple={multiple}
          invalid={error}
        />
      )}
      {hasValue && (
        <Grid
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
            'repeat(4, minmax(auto, 10rem))',
          ]}
          gridAutoRows="1fr"
          gap={2}
        >
          {multiple && (
            <FieldArray
              name={name}
              render={(arrayHelpers) => (
                <>
                  {value.map((image, i) => (
                    <Box
                      position="relative"
                      key={image.id}
                      gridColumn={i === 0 ? '1 / span 2' : undefined}
                      gridRow={i === 0 ? '1 / span 2' : undefined}
                      borderWidth="1px"
                      borderRadius="md"
                    >
                      <AspectRatioBox ratio={1}>
                        <Field
                          name={`${name}[${i}]`}
                          as={EditableImage}
                          objectFit="contain"
                          src={
                            image.edited?.preview ||
                            image.original?.preview ||
                            image.url
                          }
                          value={image}
                          containerProps={{ mb: 0 }}
                          onBlur={onBlur}
                        />
                      </AspectRatioBox>
                      <Flex position="absolute" m={2} top="0" right="0">
                        <Stack spacing={1} mr={1}>
                          <IconButton
                            icon="arrow-up"
                            isDisabled={i === 0}
                            onClick={() => arrayHelpers.swap(i, i - 1)}
                            size="xs"
                          />
                          <IconButton
                            icon="arrow-down"
                            isDisabled={!image.id || i === value.length - 1}
                            onClick={() => arrayHelpers.swap(i, i + 1)}
                            size="xs"
                          />
                        </Stack>
                        <IconButton
                          icon="delete"
                          variantColor="red"
                          onClick={() => arrayHelpers.remove(i)}
                          size="xs"
                        />
                      </Flex>
                    </Box>
                  ))}
                </>
              )}
            />
          )}
          {!multiple && (
            <Box
              position="relative"
              gridColumn="1 / span 2"
              gridRow="1 / span 2"
              borderWidth="1px"
              borderRadius="md"
              bg="gray.50"
            >
              <AspectRatioBox ratio={1}>
                <Field
                  name={name}
                  as={EditableImage}
                  objectFit="contain"
                  src={
                    value.edited?.preview ||
                    value.original?.preview ||
                    value.url
                  }
                  value={value}
                  onBlur={onBlur}
                />
              </AspectRatioBox>
              <Flex position="absolute" m={2} top="0" right="0">
                <IconButton
                  icon="delete"
                  variantColor="red"
                  onClick={() => setValue(null)}
                  size="xs"
                />
              </Flex>
            </Box>
          )}
          <FileInput onChange={handleSelect} multiple />
        </Grid>
      )}
    </Box>
  );
}

export default ImageInput;
