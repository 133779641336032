import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Textarea,
} from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Field } from '../../components/Form';
import Label from '../../components/Label';
import Placeholder from '../../components/Placeholder';

const UpdateAddressMutation = gql`
  mutation UpdateAddressMutation($id: String!, $data: AddressUpdateInput!) {
    updateOneAddress(where: { id: $id }, data: $data) {
      id
      name
      line
      city
      zip
      country
      phone
      email
      vatNumber
    }
  }
`;

function Address({ data, isBilling, isBillingSame }) {
  const {
    name,
    line,
    zip,
    city,
    country,
    phone,
    email,
    vatNumber,
    data: addressJson,
    note,
  } = data;

  const [update] = useMutation(UpdateAddressMutation);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ref = useRef();

  return (
    <Box position="relative">
      <Label mb={1}>{isBilling ? 'Számlázási cím' : 'Szállítási cím'}</Label>
      <Button size="xs" onClick={onOpen} position="absolute" top={0} right={0}>
        Módosítás
      </Button>
      <Box>
        {isBillingSame ? (
          <Placeholder>Megegyezik a szállítási címmel</Placeholder>
        ) : (
          <>
            <Text>{name}</Text>
            <Text>{line}</Text>
            <Text>
              {zip} {city}
            </Text>
            <Text>{country}</Text>
            {addressJson && <Text>{addressJson.name}</Text>}
            {phone && <Text>{phone}</Text>}
            {email && <Text>{email}</Text>}
            {vatNumber && <Text>{vatNumber}</Text>}
            {note && <Text>{note}</Text>}
          </>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={ref}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              ...data,
              data: JSON.stringify(data.data, null, 2),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await update({
                variables: {
                  id: values.id,
                  data: {
                    ...values,
                    data: JSON.parse(values.data),
                    __typename: undefined,
                  },
                },
              });

              setSubmitting(false);
              onClose();
            }}
          >
            {({ isSubmitting, values: { data } }) => (
              <Form>
                <ModalHeader>
                  {isBilling ? 'Számlázási cím' : 'Szállítási cím'} módosítása
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Field name="name" label="Név" />
                  <Field name="line" label="Cím" />
                  <Field name="zip" label="Irányítószám" />
                  <Field name="city" label="Település" />
                  <Field name="country" label="Ország" />
                  {data && (
                    <Field
                      name="data"
                      label="Adat"
                      as={Textarea}
                      minHeight="10rem"
                      readOnly
                    />
                  )}
                  {!isBilling && <Field name="phone" label="Telefonszám" />}
                  {!isBilling && <Field name="email" label="Email cím" />}
                  {isBilling && <Field name="vatNumber" label="Adószám" />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onClose}>
                    Mégse
                  </Button>
                  <Button
                    variantColor="blue"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    Mentés
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Address;
