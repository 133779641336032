import styled from '@emotion/styled';
import { PseudoBox, Grid } from '@chakra-ui/core';

export const Row = styled(PseudoBox)``;

Row.defaultProps = {
  display: ['block', 'contents'],
};

export const Cell = styled(PseudoBox)``;

Cell.defaultProps = {
  bg: 'white',
  pt: [0, 4],
  pb: [0, 4],
  pl: 4,
  pr: 4,
  _first: {
    pt: [4, null],
  },
  _last: {
    pb: [4, null],
  },
};

const GridTable = styled(Grid)``;

GridTable.defaultProps = {
  gridRowGap: '1px',
  bg: 'gray.200',
};

export default GridTable;
