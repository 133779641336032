import React from 'react';
import { Select } from '@chakra-ui/core';
import { gql, useQuery } from '@apollo/client';
import useAutoSelect from './useAutoSelect';

const ManufacturersQuery = gql`
  query ManufacturersQuery {
    manufacturers {
      id
      name
    }
  }
`;

function ManufacturerSelect({ ...rest }) {
  const { data } = useQuery(ManufacturersQuery);

  useAutoSelect({ items: data?.manufacturers, ...rest });

  return (
    <Select {...rest}>
      <option value="" disabled>
        Kérlek válassz
      </option>
      {data?.manufacturers.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
}

export default ManufacturerSelect;
