import { Box, ColorModeProvider, Divider, PseudoBox } from '@chakra-ui/core';
import React from 'react';
import {
  FaBoxes,
  FaCarSide,
  FaRegBuilding,
  FaShippingFast,
  FaShoppingCart,
  FaUsers,
  FaWarehouse,
} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import Container from './Container';
import Label from './Label';
import StoreBrand from './StoreBrand';

function NavItem({ children, to, ...rest }) {
  const { pathname } = useLocation();

  const match = pathname.startsWith(to);

  return (
    <PseudoBox
      as={Link}
      to={to}
      display="flex"
      cursor="pointer"
      alignItems="center"
      p={4}
      transition="all 0.2s"
      fontWeight="medium"
      outline="none"
      _focus={{ shadow: 'outline' }}
      color="gray.300"
      _hover={{
        color: 'gray.100',
        transform: 'translateX(0.25rem)',
      }}
      {...(match && {
        _hover: {},
        color: 'gray.100',
        fontWeight: 'bold',
        rounded: 'sm',
        bg: 'blackAlpha.300',
      })}
      {...rest}
    >
      {children}
    </PseudoBox>
  );
}

function Section({ title, children, ...rest }) {
  return (
    <Box {...rest}>
      <Label mb={1}>{title}</Label>
      {children}
    </Box>
  );
}

function Navigation({ ...rest }) {
  return (
    <ColorModeProvider value="dark">
      <Container>
        <Box
          as="nav"
          width="2xs"
          minHeight="100vh"
          bg="gray.700"
          zIndex="banner"
          position="sticky"
          top="0"
          {...rest}
        >
          <Link to="/">
            <Box height="header" bg="gray.800" boxShadow="sm">
              <StoreBrand />
            </Box>
          </Link>
          <Box p={4} overflowY="auto" maxHeight="calc(100vh - 4rem)">
            <Section title="Általános" py={2}>
              <NavItem to="/orders">
                <Box as={FaShoppingCart} mr={2} />
                Rendelések
              </NavItem>
              <NavItem to="/products">
                <Box as={FaCarSide} mr={2} />
                Termékek
              </NavItem>
              <NavItem to="/collections">
                <Box as={FaBoxes} mr={2} />
                Kollekciók
              </NavItem>
              <NavItem to="/manufacturers">
                <Box as={FaRegBuilding} mr={2} />
                Gyártók
              </NavItem>
              <NavItem to="/brands">
                <Box as={FaWarehouse} mr={2} />
                Márkák
              </NavItem>
              <NavItem to="/shipping-methods">
                <Box as={FaShippingFast} mr={2} />
                Szállítási módok
              </NavItem>
            </Section>
            <Divider borderColor="gray.900" />
            <Section title="Haladó" py={2}>
              <NavItem to="/users">
                <Box as={FaUsers} mr={2} />
                Felhasználók
              </NavItem>
            </Section>
          </Box>
        </Box>
      </Container>
    </ColorModeProvider>
  );
}

export default Navigation;
