import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Select,
  Tag,
} from '@chakra-ui/core';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const OrdersQuery = gql`
  query OrdersQuery(
    $first: Int!
    $after: OrderWhereUniqueInput
    $query: String
    $status: OrderStatus
  ) {
    items: orders(
      first: $first
      after: $after
      where: {
        AND: [
          {
            OR: [
              { user: { name: { contains: $query } } }
              { user: { id: { equals: $query } } }
              { id: { equals: $query } }
              { items: { some: { product: { id: { equals: $query } } } } }
            ]
          }
          { status: { equals: $status } }
        ]
      }
      orderBy: { createdAt: desc }
    ) {
      id
      createdAt
      status
      amount
      user {
        id
        name
      }
      items {
        id
        type
        quantity
      }
    }
    count: ordersCount(
      where: {
        AND: [
          {
            OR: [
              { user: { name: { contains: $query } } }
              { user: { id: { equals: $query } } }
              { id: { equals: $query } }
              { items: { some: { product: { id: { equals: $query } } } } }
            ]
          }
          { status: { equals: $status } }
        ]
      }
    )
  }
`;

export function getStatusColor(status) {
  switch (status) {
    case 'NEW':
      return 'purple';
    case 'PAID':
      return 'cyan';
    case 'READY':
      return 'blue';
    case 'SHIPPED':
      return 'teal';
    case 'DELIVERED':
      return 'green';
    case 'CANCELLED':
      return undefined;
    default:
      return undefined;
  }
}

export function getStatusText(status) {
  switch (status) {
    case 'NEW':
      return 'Új';
    case 'PAID':
      return 'Fizetve';
    case 'READY':
      return 'Átvehető';
    case 'SHIPPED':
      return 'Kiszállítás alatt';
    case 'DELIVERED':
      return 'Teljesítve';
    case 'CANCELLED':
      return 'Törölve';
    default:
      return status;
  }
}

function Orders() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Rendelések</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable
        templateColumns={['1fr', '1fr 1fr 1fr auto auto']}
        query={OrdersQuery}
        renderFiltering={({ variables, setVariables }) => (
          <Select
            value={variables.orderStatus}
            placeholder="Rendelés állapota"
            maxWidth="xs"
            onChange={(e) => {
              setVariables({
                ...variables,
                status: e.target.value || undefined,
              });
            }}
          >
            <option value="NEW">Új</option>
            <option value="PAID">Fizetve</option>
            <option value="READY">Átvehető</option>
            <option value="SHIPPED">Kiszállítás alatt</option>
            <option value="DELIVERED">Teljesítve</option>
            <option value="CANCELLED">Törölve</option>
          </Select>
        )}
      >
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell>
                <Label>Létrehozva</Label>
              </Cell>
              <Cell>
                <Label>Vásárló</Label>
              </Cell>
              <Cell>
                <Label>Státusz</Label>
              </Cell>
              <Cell>
                <Label>Mennyiség</Label>
              </Cell>
              <Cell textAlign="right">
                <Label>Összeg</Label>
              </Cell>
            </Row>
            {items.map(
              ({ id, createdAt, user: { name }, items, status, amount }) => (
                <Row key={id} as={Link} to={`/orders/${id}`}>
                  <Cell label="Létrehozva">
                    {format(new Date(createdAt), 'Pp', { locale: hu })}
                  </Cell>
                  <Cell label="Vásárló">{name}</Cell>
                  <Cell label="Státusz">
                    <Tag variantColor={getStatusColor(status)} size="sm">
                      {getStatusText(status)}
                    </Tag>
                  </Cell>
                  <Cell label="Mennyiség">
                    {items
                      .filter((i) => i.type === 'PRODUCT')
                      .reduce((sum, curr) => sum + curr.quantity, 0)}{' '}
                    db
                  </Cell>
                  <Cell textAlign={['left', 'right']} label="Összeg">
                    {amount} Ft
                  </Cell>
                </Row>
              )
            )}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default Orders;
