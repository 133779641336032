import { gql, useMutation } from '@apollo/client';
import { Box, Button, ColorModeProvider } from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import Container from '../components/Container';
import { Error, Field } from '../components/Form';
import StoreBrand from '../components/StoreBrand';
import { LoggedInQuery } from '../graphql';

const LoginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
      }
    }
  }
`;

function Login() {
  const [login, { data, loading, error, client }] = useMutation(LoginMutation);

  useEffect(() => {
    if (data) {
      client.writeQuery({
        query: LoggedInQuery,
        data: { loggedIn: true },
      });
    }
  }, [client, data]);

  return (
    <ColorModeProvider value="dark">
      <Container>
        <Box m="auto" bg="gray.700" minHeight="100vh">
          <Box height={24} bg="gray.800" boxShadow="sm">
            <StoreBrand fontSize="2xl" />
          </Box>
          <Box p={6} maxWidth="sm" m="0 auto">
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={yup.object({
                email: yup
                  .string()
                  .email('Nem megfelelő email formátum')
                  .required('Kötelező'),
                password: yup.string().required('Kötelező'),
              })}
              onSubmit={({ email, password }) =>
                login({
                  variables: { email, password },
                })
              }
            >
              <Form>
                <Field name="email" label="Email" type="email" isRequired />
                <Field
                  name="password"
                  label="Jelszó"
                  type="password"
                  isRequired
                />
                <Button
                  mt={2}
                  width="100%"
                  variantColor="blue"
                  type="submit"
                  isLoading={loading}
                >
                  Bejelentkezés
                </Button>
                <Error error={error} />
              </Form>
            </Formik>
          </Box>
        </Box>
      </Container>
    </ColorModeProvider>
  );
}

export default Login;
