import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from './Loading';
import { LogoutMutation, LoggedInQuery, ProfileQuery } from '../graphql';

function AuthGuard({ children }) {
  const { pathname, state, search } = useLocation();

  const { data: local } = useQuery(LoggedInQuery);

  const { data, loading, error, refetch, client } = useQuery(ProfileQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const [logout] = useMutation(LogoutMutation);

  const { loggedIn } = local || {};

  useEffect(() => {
    if (data) {
      client.writeQuery({
        query: LoggedInQuery,
        data: { loggedIn: true },
      });
    }

    if (data && (data.me === null || data.me.role !== 'ADMIN')) {
      logout();
      client.writeQuery({
        query: LoggedInQuery,
        data: { loggedIn: false },
      });
    }

    if (error) {
      client.writeQuery({
        query: LoggedInQuery,
        data: { loggedIn: false },
      });
    }
  }, [client, data, error, logout]);

  useEffect(() => {
    if (loggedIn && error) {
      refetch();
    }
  }, [data, error, loggedIn, refetch]);

  useEffect(() => {
    if (loggedIn === false && data && pathname === '/login') {
      // Clear cache
      window.location.reload();
    }
  }, [data, loggedIn, pathname]);

  if (loggedIn === true) {
    if (loading) {
      return <Loading minHeight="100vh" bg="gray.50" />;
    }

    if (pathname === '/login') {
      return <Navigate to={state?.from || '/'} />;
    }

    return children;
  }

  if (loggedIn === false) {
    if (pathname !== '/login') {
      return <Navigate to="/login" state={{ from: { pathname, search } }} />;
    }

    return children;
  }

  return <Loading minHeight="100vh" bg="gray.50" />;
}

export default AuthGuard;
