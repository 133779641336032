import { Heading } from '@chakra-ui/core';
import styled from '@emotion/styled';

const Label = styled(Heading)``;

Label.defaultProps = {
  fontSize: 'xs',
  color: 'gray.600',
  letterSpacing: 'wide',
  textTransform: 'uppercase',
  fontWeight: 'semibold',
};

export default Label;
