import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
} from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const ManufacturersQuery = gql`
  query ManufacturersQuery(
    $first: Int!
    $after: ManufacturerWhereUniqueInput
    $query: String
  ) {
    items: manufacturers(
      first: $first
      after: $after
      where: { name: { contains: $query } }
    ) {
      id
      name
      image {
        url
      }
    }
    count: manufacturersCount(where: { name: { contains: $query } })
  }
`;

function Manufacturers() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Gyártók</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable
        templateColumns={['1fr', '5rem 1fr']}
        query={ManufacturersQuery}
        newLink="/manufacturers/new"
      >
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell>
                <Label>Logó</Label>
              </Cell>
              <Cell>
                <Label>Név</Label>
              </Cell>
            </Row>
            {items.map(({ id, name, image }) => (
              <Row key={id} as={Link} to={`/manufacturers/${id}`}>
                <Cell label="Logó">
                  <Image src={image.url} objectFit="contain" width="5rem" />
                </Cell>
                <Cell>{name}</Cell>
              </Row>
            ))}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default Manufacturers;
