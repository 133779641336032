import { Box, Stack, Text } from '@chakra-ui/core';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { Form, Formik } from 'formik';
import React, { forwardRef } from 'react';
import * as yup from 'yup';
import Card from '../../components/Card';
import { Field } from '../../components/Form';
import Label from '../../components/Label';

const UserForm = forwardRef(({ data, onSubmit, isReadOnly }, ref) => {
  const { name, email, updatedAt, createdAt, role } = data || {
    name: '',
    email: '',
  };

  return (
    <Formik
      innerRef={ref}
      key={updatedAt}
      initialValues={{
        name,
        email,
      }}
      enableReinitialize
      validationSchema={yup.object({
        name: yup.string().required('Kötelező'),
        email: yup.string().required('Kötelező'),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <Stack spacing={2}>
          <Card position="relative">
            <Field
              name="name"
              label="Név"
              isRequired
              isReadOnly={isReadOnly}
              variant={isReadOnly ? 'unstyled' : undefined}
              labelProps={isReadOnly && { pb: 0, lineHeight: 'shorter' }}
              containerProps={isReadOnly && { lineHeight: 'shorter' }}
            />
            <Field
              name="email"
              label="Email"
              isRequired
              isReadOnly={isReadOnly}
              variant={isReadOnly ? 'unstyled' : undefined}
              labelProps={isReadOnly && { pb: 0, lineHeight: 'shorter' }}
              containerProps={isReadOnly && { lineHeight: 'shorter' }}
            />
            <Box mb={2}>
              <Label>Létrehozva</Label>
              <Text>{format(new Date(createdAt), 'Pp', { locale: hu })}</Text>
            </Box>
            <Box>
              <Label>Jogosultsági szint</Label>
              <Text>{role}</Text>
            </Box>
          </Card>
        </Stack>
      </Form>
    </Formik>
  );
});

export default UserForm;
