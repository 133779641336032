import { Stack, Divider } from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import React, { forwardRef } from 'react';
import * as yup from 'yup';
import Card from '../../components/Card';
import { Field, ImageInput } from '../../components/Form';

const BrandForm = forwardRef(({ data, onSubmit }, ref) => {
  const { name, image, updatedAt } = data || {
    name: '',
    image: '',
  };

  return (
    <Formik
      innerRef={ref}
      key={updatedAt}
      initialValues={{
        name,
        image,
      }}
      enableReinitialize
      validationSchema={yup.object({
        name: yup.string().required('Kötelező'),
        image: yup.object().required('Kötelező'),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <Stack spacing={2}>
          <Card>
            <Field name="name" label="Név" isRequired />
            <Divider my={4} />
            <Field
              as={ImageInput}
              name="image"
              label="Kép"
              isRequired
              containerProps={{ width: '100%' }}
            />
          </Card>
        </Stack>
      </Form>
    </Formik>
  );
});

export default BrandForm;
