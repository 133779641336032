import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  theme,
} from '@chakra-ui/core';
import { ResponsiveLine } from '@nivo/line';
import { TableTooltip } from '@nivo/tooltip';
import { add, format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import Label from '../components/Label';
import Loading from '../components/Loading';

const Chip = ({ color }) => (
  <span
    style={{
      display: 'block',
      width: '12px',
      height: '12px',
      background: color,
    }}
  />
);

const commonProps = {
  margin: { top: 16, right: 16, bottom: 32, left: 56 },
  gridYValues: 3,
  gridXValues: 3,
  axisBottom: {
    format: '%b %d',
    tickValues: 3,
  },
  colors: [theme.colors.blue[100], theme.colors.blue[500]],
  lineWidth: 3,
  useMesh: true,
  enableSlices: 'x',
  xScale: {
    type: 'time',
    format: '%Y-%m-%d',
    useUTC: false,
    precision: 'day',
  },
  yScale: {
    type: 'linear',
    min: 0,
  },
  xFormat: 'time:%Y-%m-%d',
  enablePoints: false,
  sliceTooltip: ({ slice, axis }) => {
    const otherAxis = axis === 'x' ? 'y' : 'x';

    return (
      <TableTooltip
        rows={slice.points.map((point) => [
          <Chip key="chip" color={point.serieColor} />,
          format(
            add(new Date(point.data.x), { days: point.serieId }),
            'yyyy-MM-dd'
          ),
          <strong key="value">{point.data[`${otherAxis}Formatted`]}</strong>,
        ])}
      />
    );
  },
};

const DashboardQuery = gql`
  query DashboardQuery {
    dashboard(days: 28)
  }
`;

function Home() {
  const { data: d, loading } = useQuery(DashboardQuery, {});

  if (loading) {
    return <Loading />;
  }

  const numberOfDays = d.dashboard?.length;
  const preTotalSales = d.dashboard
    ?.slice(0, numberOfDays / 2)
    .reduce((acc, curr) => {
      return [
        ...acc,
        {
          x: format(
            add(new Date(curr.date), { days: numberOfDays / 2 }),
            'yyyy-MM-dd'
          ),
          y: curr.totalSales || 0,
        },
      ];
    }, []);

  const totalSales = d.dashboard
    ?.slice(numberOfDays / 2)
    .reduce((acc, curr) => {
      return [
        ...acc,
        {
          x: format(new Date(curr.date), 'yyyy-MM-dd'),
          y: curr.totalSales || 0,
        },
      ];
    }, []);

  const preTotalOrders = d.dashboard
    ?.slice(0, numberOfDays / 2)
    .reduce((acc, curr) => {
      return [
        ...acc,
        {
          x: format(
            add(new Date(curr.date), { days: numberOfDays / 2 }),
            'yyyy-MM-dd'
          ),
          y: curr.totalOrders || 0,
        },
      ];
    }, []);

  const sales = d.dashboard?.slice(numberOfDays / 2).reduce((acc, curr) => {
    return [
      ...acc,
      {
        x: format(new Date(curr.date), 'yyyy-MM-dd'),
        y: curr.sales || 0,
      },
    ];
  }, []);

  const preSales = d.dashboard
    ?.slice(0, numberOfDays / 2)
    .reduce((acc, curr) => {
      return [
        ...acc,
        {
          x: format(
            add(new Date(curr.date), { days: numberOfDays / 2 }),
            'yyyy-MM-dd'
          ),
          y: curr.sales || 0,
        },
      ];
    }, []);

  const totalOrders = d.dashboard
    ?.slice(numberOfDays / 2)
    .reduce((acc, curr) => {
      return [
        ...acc,
        {
          x: format(new Date(curr.date), 'yyyy-MM-dd'),
          y: curr.totalOrders || 0,
        },
      ];
    }, []);

  const preOrders = d.dashboard
    ?.slice(0, numberOfDays / 2)
    .reduce((acc, curr) => {
      return [
        ...acc,
        {
          x: format(
            add(new Date(curr.date), { days: numberOfDays / 2 }),
            'yyyy-MM-dd'
          ),
          y: curr.orders || 0,
        },
      ];
    }, []);

  const orders = d.dashboard?.slice(numberOfDays / 2).reduce((acc, curr) => {
    return [
      ...acc,
      {
        x: format(new Date(curr.date), 'yyyy-MM-dd'),
        y: curr.orders || 0,
      },
    ];
  }, []);

  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} to="/">
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Grid
        templateColumns={{
          base: 'repeat(auto-fill, minmax(16rem, 1fr));',
          sm: 'repeat(auto-fill, minmax(24rem, 1fr));',
        }}
        gridGap={4}
        autoRows="20rem"
      >
        <Box
          justifySelf="stretch"
          bg="white"
          borderWidth={1}
          borderRadius="md"
          p={4}
          pt={8}
          position="relative"
        >
          <Label position="absolute" top={4} left={4}>
            Összes eladás
          </Label>
          <ResponsiveLine
            data={[
              { id: `-${numberOfDays / 2}`, data: preTotalSales },
              { id: '0', data: totalSales },
            ]}
            axisLeft={{
              tickValues: 3,
              format: (value) => `${value / 1000} eFt`,
            }}
            yFormat={(value) => `${Math.round(value / 1000)} eFt`}
            {...commonProps}
          />
        </Box>
        <Box
          justifySelf="stretch"
          bg="white"
          borderWidth={1}
          borderRadius="md"
          p={4}
          pt={8}
          position="relative"
        >
          <Label position="absolute" top={4} left={4}>
            Összes rendelés
          </Label>
          <ResponsiveLine
            data={[
              { id: `-${numberOfDays / 2}`, data: preTotalOrders },
              { id: '0', data: totalOrders },
            ]}
            axisLeft={{
              tickValues: 3,
              format: (value) => `${value} db`,
            }}
            yFormat={(value) => `${value} db`}
            {...commonProps}
          />
        </Box>
        <Box
          justifySelf="stretch"
          bg="white"
          borderWidth={1}
          borderRadius="md"
          p={4}
          pt={8}
          position="relative"
        >
          <Label position="absolute" top={4} left={4}>
            Eladások
          </Label>
          <ResponsiveLine
            data={[
              { id: `-${numberOfDays / 2}`, data: preSales },
              { id: '0', data: sales },
            ]}
            axisLeft={{
              tickValues: 3,
              format: (value) => `${value / 1000} eFt`,
            }}
            yFormat={(value) => `${Math.round(value / 1000)} eFt`}
            {...commonProps}
          />
        </Box>
        <Box
          justifySelf="stretch"
          bg="white"
          borderWidth={1}
          borderRadius="md"
          p={4}
          pt={8}
          position="relative"
        >
          <Label position="absolute" top={4} left={4}>
            Rendelések
          </Label>
          <ResponsiveLine
            data={[
              {
                id: `-${numberOfDays / 2}`,
                data: preOrders,
              },
              { id: '0', data: orders },
            ]}
            axisLeft={{
              tickValues: 3,
              format: (value) => `${value} db`,
            }}
            yFormat={(value) => `${value} db`}
            {...commonProps}
          />
        </Box>
      </Grid>
    </Box>
  );
}

export default Home;
