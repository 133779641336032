import React from 'react';
import Placeholder from './Placeholder';

function Value({ children }) {
  if (children) {
    return children;
  }

  return <Placeholder>Nincs megadva</Placeholder>;
}

export default Value;
