import React from 'react';
import { Grid, Stack } from '@chakra-ui/core';

export function Main({ children }) {
  return <Stack spacing={2}>{children}</Stack>;
}

export function Sidebar({ children }) {
  return <Stack spacing={2}>{children}</Stack>;
}

function SidebarLayout({ children }) {
  return (
    <Grid
      gridTemplateColumns={{
        xs: '1fr',
        md: '2fr 1fr',
        lg: '1fr',
        xl: '2fr 1fr',
      }}
      gridGap={2}
    >
      {children}
    </Grid>
  );
}

export default SidebarLayout;
