import { gql } from '@apollo/client';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
} from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataTable, { Cell, Row } from '../../components/DataTable';
import Label from '../../components/Label';

const BrandsQuery = gql`
  query BrandsQuery(
    $first: Int!
    $after: BrandWhereUniqueInput
    $query: String
  ) {
    items: brands(
      first: $first
      after: $after
      where: { name: { contains: $query } }
    ) {
      id
      name
      image {
        url
      }
    }
    count: brandsCount(where: { name: { contains: $query } })
  }
`;

function Brands() {
  return (
    <Box>
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={2}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Márkák</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <DataTable
        templateColumns={['1fr', '5rem 1fr']}
        query={BrandsQuery}
        newLink="/brands/new"
      >
        {(items) => (
          <>
            <Row display={['none', 'contents']} role="none">
              <Cell>
                <Label>Logó</Label>
              </Cell>
              <Cell>
                <Label>Név</Label>
              </Cell>
            </Row>
            {items.map(({ id, name, image }) => (
              <Row key={id} as={Link} to={`/brands/${id}`}>
                <Cell label="Logó">
                  <Image src={image.url} objectFit="contain" width="5rem" />
                </Cell>
                <Cell label="Név">{name}</Cell>
              </Row>
            ))}
          </>
        )}
      </DataTable>
    </Box>
  );
}

export default Brands;
